import { Card, CardHeader, useTheme } from "@mui/material";
import { PropsWithChildren } from "react";

interface PortalCardProps{
    title?: string
}

const PortalCard = (props: PropsWithChildren<PortalCardProps>) => {
    const theme = useTheme();
    return (<Card sx={{
        width: "100%",
        p: 2.5,
        border: '1px solid',
        borderRadius: 2,
        borderColor: theme.palette.divider
    }}
        elevation={0}>
            {(props.title !== undefined) && <CardHeader sx={{ p: 0, pb: 2.5 }} title={props.title}></CardHeader>}
        {props.children}
    </Card>);
}

export default PortalCard;