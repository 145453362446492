import { PaletteOptions, ThemeOptions, createTheme} from "@mui/material";
import { cyan, green, red, yellow } from '@mui/material/colors';
import { TypographyOptions } from "@mui/material/styles/createTypography";

const grey = {
  0: '#ffffff',
  50: '#fafafa',
  100: '#f5f5f5',
  200: '#f0f0f0',
  300: '#d9d9d9',
  400:  '#bfbfbf',
  500: '#8c8c8c',
  600: '#595959',
  700: '#262626',
  800: '#141414',
  900: '#000000',
  A50: '#fafafa',
  A100: '#bfbfbf',
  A200: '#434343',
  A400: '#1f1f1f',
  A700: '#fafafb',
  A800: '#e6ebf1'
}

const paletteOptions: PaletteOptions={
  mode: 'light',
  common: {
    black: '#000',
    white: '#fff'
  },
  primary:{
    main:"#003f4e",
    50: "#d4f1ff",
    100: "#b1d8e4",
    200: "#8ebaca",
    300: "#699daf",
    400: "#4d889c",
    500: "#2d7388",
    600: "#216578",
    700: "#105163",
    800: "#003e4e",
    900: "#002a37"
 },
 secondary:{
  100: grey[100],
  200: grey[200],
  light:grey[300],
  400:grey[400],
  main:grey[500],
  600: grey[600],
  dark: grey[700],
  800:grey[800],
  A100: grey[0],
  A200: grey.A400,
  A700: grey.A700,
  contrastText: grey[0]
 },
 error:{
  100: red[100],
  200: red[200],
  300: red[300],
  400: red[400],
  500: red[500],
  600: red[600],
  700: red[700],
  800: red[800],
  900: red[900],
  A100: red.A100,
  A200: red.A200,
  A400: red.A400,
  A700: red.A700,
  main: red[500],
  contrastText: grey[0]
 },
 warning: {
  100: yellow[100],
  200: yellow[200],
  300: yellow[300],
  400: yellow[400],
  500: yellow[500],
  600: yellow[600],
  700: yellow[700],
  800: yellow[800],
  900: yellow[900],
  A100: yellow.A100,
  A200: yellow.A200,
  A400: yellow.A400,
  A700: yellow.A700,
  main: yellow[500],
  contrastText: grey[0]
 },
 info:{
  100: cyan[100],
  200: cyan[200],
  300: cyan[300],
  400: cyan[400],
  500: cyan[500],
  600: cyan[600],
  700: cyan[700],
  800: cyan[800],
  900: cyan[900],
  A100: cyan.A100,
  A200: cyan.A200,
  A400: cyan.A400,
  A700: cyan.A700,
  main: cyan[500],
  contrastText: grey[0]
 },
 success:{
  100: green[100],
  200: green[200],
  300: green[300],
  400: green[400],
  500: green[500],
  600: green[600],
  700: green[700],
  800: green[800],
  900: green[900],
  A100: green.A100,
  A200: green.A200,
  A400: green.A400,
  A700: green.A700,
  main: green[500],
  contrastText: grey[0]
 },
 grey:grey,
 text:{
  primary: grey[700],
  secondary: grey[500],
  disabled: grey[400]
 },
action: {
  disabled: grey[300]
},
divider: grey[200],
background: {
  paper: grey[0],
  default: grey.A50
}

};

const typographyOptions: TypographyOptions = {
    htmlFontSize: 16,
    fontFamily: 'roboto',
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 600,
    h1: {
      fontWeight: 600,
      fontSize: '2.375rem',
      lineHeight: 1.21
    },
    h2: {
      fontWeight: 600,
      fontSize: '1.875rem',
      lineHeight: 1.27
    },
    h3: {
      fontWeight: 600,
      fontSize: '1.5rem',
      lineHeight: 1.33
    },
    h4: {
      fontWeight: 600,
      fontSize: '1.25rem',
      lineHeight: 1.4
    },
    h5: {
      fontWeight: 600,
      fontSize: '1rem',
      lineHeight: 1.5
    },
    h6: {
      fontWeight: 400,
      fontSize: '0.875rem',
      lineHeight: 1.57
    },
    caption: {
      fontWeight: 400,
      fontSize: '0.75rem',
      lineHeight: 1.66
    },
    body1: {
      fontSize: '0.875rem',
      lineHeight: 1.57
    },
    body2: {
      fontSize: '0.75rem',
      lineHeight: 1.66
    },
    subtitle1: {
      fontSize: '0.875rem',
      fontWeight: 600,
      lineHeight: 1.57
    },
    subtitle2: {
      fontSize: '0.75rem',
      fontWeight: 500,
      lineHeight: 1.66
    },
    overline: {
      lineHeight: 1.66
    },
    button: {
      textTransform: 'capitalize'
    }
  };






const themeOptions: ThemeOptions ={
    palette:paletteOptions,
    typography: typographyOptions
};

export default createTheme(themeOptions);