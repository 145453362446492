import { Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import BasePage from "../layout/base/BasePage";
import Image from "./Image";
import { PropsWithChildren } from "react";

interface VerticalLayoutPageProps {
  appBarTitle: string,
  title: string,
}

const VerticalLayoutPage = (props: PropsWithChildren<VerticalLayoutPageProps>) => {
  const theme = useTheme();
  const scaleWithSize  = useMediaQuery(theme.breakpoints.down('sm'))
  return (
    <BasePage useLinks={true} title={props.appBarTitle}>
        <Stack width="100%" spacing={2} alignItems="center">
          <Typography variant="h1" textAlign="center">{props.title}</Typography>
          {props.children}
          <Image sx={{width: scaleWithSize?"100%" : undefined, objectFit: "cover"}} alt="logo" src="/android-chrome-512x512.png"></Image>
        </Stack>
    </BasePage>
  );
}

export default VerticalLayoutPage;