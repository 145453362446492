import { Box, ButtonBase, Grid, Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import PortalContent from "../layout/portal/PortalContent";
import PortalCard from "../components/PortalCard";
import { AccountTreeOutlined, BallotOutlined, EventAvailableOutlined, InsightsOutlined, ReceiptLongOutlined } from "@mui/icons-material";
import { PropsWithChildren } from "react";


interface ToolCardProps {
    name: string
    toolTip: string
    url?: string
    externalUrl?: string
}
const ToolCard = (props: PropsWithChildren<ToolCardProps>) => {
    const navigate = useNavigate();
    return (
        <Grid item xs={12} sm={6} lg={4}>

            <Tooltip title={props.toolTip}>
                <span>
                    <ButtonBase
                        rel={props.externalUrl ? "norefferrer" : undefined}
                        sx={{
                            width: "100%",
                            alignItems: "center",
                            justifyContent: "center"
                        }}
                        onClick={() => {
                            if(props.url){
                                navigate(props.url!);
                            }else if(props.externalUrl){
                                window.open(props.externalUrl, "_blank");
                            }
                        
                        }}>
                        <PortalCard title={props.name}>
                            <Box width="100%">
                                {props.children}
                            </Box>
                        </PortalCard>
                    </ButtonBase>
                </span>
            </Tooltip>
        </Grid>
    );
}

const ToolsPage = () => {
    return (
        <PortalContent title="Tools" breadcrumbs={[]}>
            <Grid container spacing={3}>
                <ToolCard name="Freelancer Insights" url="/portal/tools/freelancer-insights" toolTip="Stay ahead in your field with the Freelancer Insights Tool. Get answers to crucial questions like 'How is the market evolving?', 'What skills should I acquire next?', and 'Who are the top recruiters in my industry?' With our tool, you'll gain valuable insights and stay informed on key trends and opportunities.">
                    <InsightsOutlined sx={{ fontSize: "64px" }} />
                </ToolCard>
                <ToolCard name="Schedule Free Session" toolTip="Book a free meeting session with us." externalUrl="https://outlook.office.com/bookwithme/user/9cf31257ba864e4b860bcc58a2950d85@pitontech.onmicrosoft.com/meetingtype/YD027s1hZkaMLGI6QmcTJg2?anonymous&ep=mcard">
                    <EventAvailableOutlined sx={{ fontSize: "64px" }} />
                </ToolCard>
                <ToolCard name="CV Manager" toolTip="Coming soon">
                    <ReceiptLongOutlined sx={{ fontSize: "64px" }} />
                </ToolCard>
                <ToolCard name="Projects" toolTip="Coming soon">
                    <AccountTreeOutlined sx={{ fontSize: "64px" }} />
                </ToolCard>
                <ToolCard name="Surveys" toolTip="Coming soon">
                    <BallotOutlined sx={{ fontSize: "64px" }} />
                </ToolCard>
            </Grid>
        </PortalContent>
    );
}

export default ToolsPage;