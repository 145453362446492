import { useOidcIdToken } from "@axa-fr/react-oidc";
import { useEffect,useState } from "react";



export function useScript(src:string, type: string){
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    useEffect(() => {
        const script = document.createElement('script');
        script.src = src;
        script.async = true;
        script.type =  type;
        script.addEventListener("load",() => setLoading(false));
        script.addEventListener("error", () => {
            setError(true);
            setLoading(false);
        });
        document.body.appendChild(script);
        return () => {
            document.body.removeChild(script);
        }
    },[src,type]);

    return [loading,error];
}

interface CertificationStatus {
    certified: boolean,
    label: string | undefined
}
export function useCertification():CertificationStatus{
    const oidcToken = useOidcIdToken('default');
    if(!oidcToken){
        return {certified:false, label:undefined};
    }
    if(!oidcToken.idTokenPayload){
        return {certified:false, label:undefined};
    }
    const certification = oidcToken.idTokenPayload.certification;
    if(!certification){
        return {certified:false, label:undefined};
    }
    return {certified:certification === 'certified', label:certification};
}