import { useEffect, useState } from "react";
import { Accordion, AccordionActions, AccordionDetails, AccordionSummary, Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Link, Stack, Switch, Typography, useMediaQuery, useTheme } from "@mui/material";
import { Cookie, CookieCategory, CookieGroup, cookieCategories } from "../utils/Cookies";
import Image from "./Image";

interface CategoryAccordionProps{
    category: CookieCategory, checked: boolean, callback: (id: string, value: boolean) => void
}

const CategoryAccordion = (props: CategoryAccordionProps): JSX.Element => {
    var groups: JSX.Element[] = props.category.groups.map((group: CookieGroup) => <GroupAccordion key={group.name} {...group}/>);
    return (

        <Accordion key={props.category.id}>
            <AccordionSummary sx={{
                '& .MuiAccordionSummary-content': { flexDirection: "row", justifyContent: "space-between", alignItems: "center" }
            }}>
                <Typography variant="subtitle1">{props.category.name}</Typography>
                <AccordionActions>
                    <Switch onChange={(e, value) => props.callback(props.category.id, value)} onClick={e => { e.stopPropagation() }} checked={props.checked} disabled={props.category.disabled} />
                </AccordionActions>

            </AccordionSummary>

            <AccordionDetails>
                <Typography variant="body1">{props.category.description}</Typography>
                <Box height={10}></Box>
                {groups}
            </AccordionDetails>
        </Accordion>
    );
}

const GroupAccordion = (group: CookieGroup): JSX.Element => {
    const theme = useTheme();
  const asRow = useMediaQuery(theme.breakpoints.up('sm'));
    const cookies: JSX.Element[] = group.cookies.map((value: Cookie, index:number) =>  detailsForCookie(value, index === group.cookies.length-1));
    return (
        <Accordion key={group.name}>
            <AccordionSummary sx={{
                '& .MuiAccordionSummary-content': { flexDirection: "column" }
            }}>
                <Typography variant="subtitle1">{group.name} ({group.cookies.length})</Typography>
                <Typography>{group.description}</Typography>
                <Box height={10}></Box>
                <Stack justifyContent="space-between" direction={asRow?"row" : "column"}>
                    <Typography>Vendor: {group.vendor}</Typography>
                    <Typography>Host: {group.host}</Typography>
                    <Stack direction="row">
                        <Typography >Privacy:</Typography>
                        <Link rel="noreferrer" target="_blank"  href={group.privacyUrl}>{group.privacyUrl}</Link>
                    </Stack>
                </Stack>
            </AccordionSummary>
            <AccordionDetails>
                {cookies}
            </AccordionDetails>
        </Accordion>
    );
}

function detailsForCookie(cookie: Cookie, lastItem:boolean): JSX.Element {
    return (
        <Box key={cookie.name} paddingBottom="10px">
            <Typography fontWeight="bold">{cookie.name}</Typography>
            <Typography>Duration: {cookie.duration}</Typography>
            <Typography>{cookie.description}</Typography>
            {!lastItem &&<Divider sx={{paddingTop: "10px"}}/>}
        </Box>
    );
}
interface CookieDialogProps {
    visible: boolean;
    forced: boolean;
    consent: any;
    handleConsent: (consent: any) => void;
    close: () => void;
}
const CookieDialog = (props: CookieDialogProps) => {

    
    const [consentValues, setConsentValues] = useState(props.consent);
    useEffect(() => {
        setConsentValues(props.consent);
    },[props.consent]);

    const acceptAll = () => {
        var consent: any = {};
        cookieCategories.forEach((category) => {
            consent[category.id] = true;
        });
        props.handleConsent(consent);
    }
    const acceptSelection = () => {
        props.handleConsent(consentValues);
    }
    const acceptNecessary = () => {
        var consent: any = {};
        cookieCategories.forEach((category) => {
            consent[category.id] = category.id === 'necessary';
        });
        props.handleConsent(consent);
    }

    const setConsentForCategory = (id: string, value: boolean) => {
        const update : any= { };
        update[id] = value;
        setConsentValues({ ...consentValues, ...update });
    }

    var categories: JSX.Element[] = cookieCategories.map((category: CookieCategory) => <CategoryAccordion key={category.id} category={category} checked={consentValues[category.id]} callback={setConsentForCategory}/>);
    return (
        <Dialog onClose={() => {
            if(!props.forced){
                props.close();
            }
        }} maxWidth='md' fullWidth={true} open={props.visible} scroll={"paper"}>
            <Box display="flex" flexDirection="row" justifyContent="space-between">
                <DialogTitle variant="h5">We use Cookies.</DialogTitle>
                <Image width="64px" height="64px" src="/apple-touch-icon.png" alt="Logo" padding="5px"></Image>
            </Box>
            <DialogContent dividers={true}>
                <DialogContentText variant="body1" component="div">
                    We use Cookies on our website. Some are necessary to display the website without errors, while others help us to improve this website and your experience. You can make an individual selection below.
                </DialogContentText>
                <Box height={10}></Box>
                {categories}
            </DialogContent>
            <DialogActions>
                <Button onClick={() => acceptNecessary()}>
                    Allow only Necessary
                </Button>
                <Button onClick={() => acceptSelection()}>
                    Allow selection
                </Button>
                <Button variant="contained" onClick={() => acceptAll()}>
                    Allow all
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default CookieDialog;