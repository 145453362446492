import { HomeRepairServiceOutlined, HomeRepairService, PersonOutlined, Person, InsightsOutlined, Insights } from "@mui/icons-material";
import { List, SvgIconTypeMap } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { useNavigate, useLocation } from "react-router-dom";
import NavItem from "./NavItem";
interface NavItemData {
    name: string,
    path: string,
    level: number,
    icon: OverridableComponent<SvgIconTypeMap<{}, "svg">>
    selectedIcon: OverridableComponent<SvgIconTypeMap<{}, "svg">>
    isSelected: (location: string, path: string) => boolean;
}



const navigationData: NavItemData[] = [
    {
        name: "Tools",
        path: "/portal/tools",
        level: 1,
        icon: () =><HomeRepairServiceOutlined />,
        selectedIcon: () =><HomeRepairService />,
        isSelected: (location, path) => {
            return (location === path || (location+"/") === path)
        } ,
    },
    {
        name: "Freelancer Insights",
        path: "/portal/tools/freelancer-insights",
        level: 2,
        icon: () =><InsightsOutlined />,
        selectedIcon: () =><Insights />,
        isSelected: (location, path) => location.startsWith(path),
    },
    {
        name: "Account",
        path: "/portal/account",
        level: 1,
        icon: () =><PersonOutlined />,
        selectedIcon: () =><Person />,
        isSelected: (location, path) => location.startsWith(path),
    },
];

const DrawerContent = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const path = location.pathname.replace(/\/*$/, "");
    return (        
    <List>
        {navigationData.map((value) => <NavItem key={value.name} navigateTo={() => navigate(value.path)} selected={value.isSelected(path, value.path)} {...value} />)}
    </List>);
}

export default DrawerContent;