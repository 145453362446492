import { Button, CircularProgress, Typography } from "@mui/material";
import { OidcConfiguration, OidcProvider, OidcSecure, useOidc, useOidcAccessToken, useOidcIdToken } from "@axa-fr/react-oidc";
import { useEffect } from "react";
import { OidcAccessToken } from "@axa-fr/react-oidc/dist/ReactOidc";
import { useScript } from "../utils/hooks";

declare global {
    namespace JSX {
        interface IntrinsicElements {
            ["tableau-viz"]: React.DetailedHTMLProps<
                React.HTMLAttributes<HTMLElement>,
                HTMLElement
            > &
            TableauVizProps;
        }
    }
}
interface TableauVizProps {
    src: string
    height?: React.CSSProperties["height"];
    width?: React.CSSProperties["width"];
    toolbar?: "top" | "bottom" | "hidden";
    token?: string;
    onVizLoadError?: any
}


const Loading = () => <Typography>Loading</Typography>;
const AuthenticatingError = () => <Typography>Authenticating error</Typography>;
const Authenticating = () => <Typography>Authenticating</Typography>;
const SessionLost = () => <Typography>SessionLost</Typography>;
const CallbackSuccess = () => <Typography>Success</Typography>

interface TableauViewContentProps {
    configurationName: string
}

const TableauViewContent = (props: TableauViewContentProps & TableauVizProps) => {
    const defaultIdToken = useOidcIdToken('default');
    const defaultXId = defaultIdToken.idTokenPayload?.xid;
    const { logout } = useOidc(props.configurationName);
    const idToken = useOidcIdToken(props.configurationName);
    const xId = idToken.idTokenPayload?.xid;
    const accessToken: OidcAccessToken = useOidcAccessToken(props.configurationName);

    const [loading, error] = useScript(
        'https://eu-west-1a.online.tableau.com/javascripts/api/tableau.embedding.3.latest.min.js',
        'module'
    );
    useEffect(() => {
        if (!xId || !defaultXId) {
            return;
        }
        if (defaultXId !== xId) {
            logout();
        }
    }, [defaultXId, xId, logout]);
    var child: JSX.Element;

    if (loading) {
        child = <CircularProgress />;
    } else if (error) {
        child = <Typography>Failed to load Tableau script</Typography>
    } else {
        child = <OidcSecure configurationName={props.configurationName}>
            <tableau-viz onVizLoadError={(error: any) => console.log(error)} token={accessToken.accessToken} id="tableau-viz" {...props}></tableau-viz>
        </OidcSecure>;
    }

    return child;
}

interface TableauViewProps {
    configurationName: string;
    configuration: OidcConfiguration
}

const TableauView = (props: TableauViewProps & TableauVizProps) => {
    return (
        <OidcProvider
            configurationName={props.configurationName}
            configuration={props.configuration}
            loadingComponent={Loading}
            authenticatingErrorComponent={AuthenticatingError}
            authenticatingComponent={Authenticating}
            sessionLostComponent={SessionLost}
            callbackSuccessComponent={CallbackSuccess}

        >
            <TableauViewContent {...props} />
        </OidcProvider>);
}



export default TableauView;