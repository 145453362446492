import { Menu } from "@mui/icons-material";
import { Box, IconButton,  Stack,  Typography, styled } from "@mui/material";

const DrawerHeaderStyled = styled(Box)(({ theme }) => ({
    ...theme.mixins.toolbar,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3)
}));

interface DrawerHeaderProps{
    open: boolean
    closeDrawer: () => void
}


const DrawerHeader = (props: DrawerHeaderProps) => {
    return (
        <DrawerHeaderStyled>
            <Stack
            width="100%"
             direction="row" alignItems="center" justifyContent="space-between">
                <Typography variant="h4" textAlign="center">Menu</Typography>
                {props.open && <IconButton onClick={() => {props.closeDrawer()}}><Menu/></IconButton>}
            </Stack>
            
            
            
        </DrawerHeaderStyled>
       
    );
}

export default DrawerHeader;