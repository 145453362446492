import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Toolbar, styled, useMediaQuery, useTheme } from "@mui/material";
import PrivateRoute from "../../components/PrivateRoute";
import { Outlet, useNavigate } from "react-router-dom";

import Header from "./header/Header";
import Drawer from "./drawer/Drawer";
import { useEffect, useState } from "react";
import { useCertification } from "../../utils/hooks";
import WelcomeDialog from "../../components/WelcomeDialog";

const drawerWidth = 300;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' && prop !== 'useTemporary'})<{
    open?: boolean;
    useTemporary?: boolean;
  }>(({ theme, open, useTemporary}) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
    ...(useTemporary && {
        marginLeft: 0
    })
  }));

const PortalPage = () => {
    const theme = useTheme();
    const useTemporary = useMediaQuery(theme.breakpoints.down('sm'));
    const [drawerOpen, setDrawerOpen] = useState(!useTemporary);
    return (
        <PrivateRoute>
            <Box sx={{display: "flex", width: "100%"}}>
                <Header drawerOpen={drawerOpen} toggleDrawer={() => {
                    setDrawerOpen(!drawerOpen)}}/>
                <Drawer open={drawerOpen} closeDrawer={() => {setDrawerOpen(false)}}/>
                <Main open={drawerOpen} useTemporary={useTemporary}>
                    <Toolbar/>
                    <Outlet/>
                </Main>
            </Box>
            <WelcomeDialog/>
        </PrivateRoute>
    );
}

export default PortalPage;