import { CssBaseline, ThemeProvider } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import CookieDialog from "./components/CookieDialog";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import HomePage from "./pages/HomePage";
import theme from "./themes/theme";
import { useCookies } from "react-cookie";
import { checkedConsentValues, giveInitialConsent, readConsentValues, replaceConsent, shouldShowDialog } from "./utils/Cookies";
import "@fontsource/roboto"
import { CookieDialogContext } from "./utils/CookieDialogContext";
import ErrorPage from "./pages/ErrorPage";
import PrivacyPage from "./pages/PrivacyPage";
import LegalNoticePage from "./pages/LegalNoticePage";
import PortalPage from "./layout/portal/PortalPage";
import ToolsPage from "./pages/ToolsPage";
import AccountPage from "./pages/AccountPage";
import FreelancerInsightsPage from "./pages/FreelancerInsightsPage";
import GlobalAuthProvider from "./components/GobalAuthProvider";
import EmailConfirmedPage from "./pages/EmailConfirmedPage";

// TODO
// Privacy Page
// Tableau Cookies Tableau error handling
// Components Auth

const router = createBrowserRouter([
  {
    errorElement: <ErrorPage />,
    element: <GlobalAuthProvider />,
    children: [
      {
        path: "authentication/*",
        element: <></>
      },
      {
        path: "/",
        element: <HomePage />,
      },
      {
        path: "email_confirmed",
        element: <EmailConfirmedPage/>
      },
      {
        path: "privacy",
        element: <PrivacyPage />
      },
      {
        path: "legal",
        element: <LegalNoticePage />
      },
      {
        path: "portal",
        element: <PortalPage />,
        children: [
          {
            path: "tools",
            element: <ToolsPage />,
          },
          {
            path: "tools/freelancer-insights/*",
            element: <FreelancerInsightsPage />
          },
          {
            path: "account",
            element: <AccountPage />
          }
        ]
      }]
  },

]);



const App = () => {
  const onLaunchRef = useRef<boolean>();
  const [cookieDialogOpen, openCookieDialog] = useState(false);
  const [cookies, setCookie, removeCookies] = useCookies();
  const consentCookie = cookies.cookie_consent;
  const shouldShowCookieDialog = shouldShowDialog(consentCookie);
  const currentConsent = checkedConsentValues(readConsentValues(consentCookie));
  const [ignoreConsentBanner, setIgnoreConesentBanner] = useState<boolean>(false);
  

  function onAppStart() {
    if (!shouldShowCookieDialog) {
      giveInitialConsent(currentConsent);
    }
  }
  useEffect(() => {
    if (onLaunchRef.current) {
      return;
    }
    onLaunchRef.current = true;
    onAppStart();
  });

  function removeCookieByName(name: string) {
    removeCookies(name);
  }
  const handleConsentAccept = (consent: any) => {
    const checkedConsent = checkedConsentValues(consent);
    if (shouldShowCookieDialog) {
      giveInitialConsent(checkedConsent);
      setCookie('cookie_consent', checkedConsent, { path: "/", maxAge: 31536000, sameSite: "strict" });
    } else {
      replaceConsent(checkedConsent, currentConsent, removeCookieByName);
      setCookie('cookie_consent', checkedConsent, { path: "/", maxAge: 31536000, sameSite: "strict" });
    }
    openCookieDialog(false);
  }

  return (
    <ThemeProvider theme={theme}>
      <CookieDialogContext.Provider value={{
        open: cookieDialogOpen, openDialog: () => {
          openCookieDialog(true);
        },
        ignoreBanner: ignoreConsentBanner,
        setIgnore: (ignore) => {
          setIgnoreConesentBanner(ignore);
        }
      }}>
        <React.StrictMode>
          <CssBaseline />
          <RouterProvider router={router} />
        </React.StrictMode>
        <CookieDialog handleConsent={handleConsentAccept} visible={(shouldShowCookieDialog && !ignoreConsentBanner) || cookieDialogOpen} forced={shouldShowCookieDialog} close={() => openCookieDialog(false)} consent={currentConsent} />
      </CookieDialogContext.Provider>
    </ThemeProvider>);
}

export default App;