import { OidcSecure } from "@axa-fr/react-oidc";
import { PropsWithChildren } from "react"


const PrivateRoute = (props:PropsWithChildren) => {
    
    
    return (<OidcSecure configurationName="default">
        {props.children}
    </OidcSecure>);
}

export default PrivateRoute;