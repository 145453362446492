import { Button, Stack, Typography } from "@mui/material";
import VerticalLayoutPage from "../components/VerticalLayoutPage";
import { CheckCircle } from "@mui/icons-material";
import { useOidc } from "@axa-fr/react-oidc";
import { useNavigate } from "react-router-dom";

const EmailConfirmedPage = () => {
    const navigate = useNavigate();
    const { login, isAuthenticated } = useOidc('default');
    const handleLogin = () => {
        if (isAuthenticated) {
            navigate("/portal/tools")
        } else {
            login("/portal/tools");
        }
    }
    return (
        <VerticalLayoutPage appBarTitle="Confirmed" title="Email Confirmed">
            <Stack spacing={3}>
                <CheckCircle sx={{
                    fontSize: "64px",
                    color: 'green'
                }} />
                <Button variant="contained" onClick={() => handleLogin()}>{isAuthenticated ? "Go to toolbox" : "Login"}</Button>
            </Stack>
        </VerticalLayoutPage>
    );
}

export default EmailConfirmedPage;