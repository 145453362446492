import { Link, Stack, Typography } from "@mui/material";
import BasePage from "../layout/base/BasePage";
import CookieIgnoreHandler from "../components/CookieIgnoreHandler";


const LegalNoticePage = () => {
  return (
    <CookieIgnoreHandler ignore={true}>
      <BasePage title="Legal Notice">
        <Stack spacing={3}>
          <Typography variant="h5">Legal Notice</Typography>
          <Typography variant="h5">According to §5 TMG</Typography>
          <Typography>PITON GmbH<br />Friendesstr. 13-15<br />73614 Schorndorf</Typography>
          <Typography>Register: HRB 788525<br />Court: Amtsgericht Stuttgart</Typography>
          <Typography>Represented by: Niklas Wietreck</Typography>
          <Typography variant="h5">Contact</Typography>
          <Typography>Mail: info@piton-tech.de</Typography>
          <Typography variant="h5">VAT-ID</Typography>
          <Typography>Sales tax identification number according to §27 sales tax law: DE359845931</Typography>
          <Typography variant="h5">Consumer dispute resolution</Typography>
          <Typography>EU platform for out-of-court online dispute resolution:{" "}<Link href="https://www.ec.europa.eu/consumers/odr">www.ec.europa.eu/consumers/odr</Link></Typography>
          <Typography>Note according to Section 36 Paragraph 1 VSBG: There is no willingness or obligation to participate in dispute resolution proceedings before a consumer arbitration board.</Typography>
        </Stack>
      </BasePage>
    </CookieIgnoreHandler>
  );
}

export default LegalNoticePage;