import { ListItemButton, ListItemIcon, ListItemText, SvgIconTypeMap, Typography, useTheme } from "@mui/material"
import { OverridableComponent } from "@mui/material/OverridableComponent"

interface NavProps {
    name: string
    level: number
    icon: OverridableComponent<SvgIconTypeMap<{}, "svg">>
    selected: boolean
    navigateTo: () => void
}


const NavItem = (props:NavProps) =>{
    const theme = useTheme();

    const textColor = 'text.primary';
    const iconSelectedColor = 'primary.main';

    const Icon:  OverridableComponent<SvgIconTypeMap<{}, "svg">> = props.icon;
    return (<ListItemButton 
    onClick={props.navigateTo}
    selected={props.selected}
    sx={{
        zIndex:1201,
        pl: `${props.level*28}px`,
        py: props.level === 1 ? 1.25 : 1,
        ...{
            '&:hover':{
                bgcolor: 'primary.light'
            },
            '&.Mui-selected': {
                bgcolor: 'primary.light',
                borderRight: `2px solid ${theme.palette.primary.main}`,
                color: iconSelectedColor,
                '&:hover':{
                    color: iconSelectedColor,
                    bgcolor: 'primary.light'
                }
            },
        },
    }} >
        <ListItemIcon
            color={props.selected ? iconSelectedColor : textColor}
            sx={{
                minWidth: 28,
                color: props.selected ? iconSelectedColor : textColor
            }}
        >
            <Icon htmlColor={props.selected ? iconSelectedColor : textColor}/>
        </ListItemIcon>
        <ListItemText
          primary={
            <Typography variant="h6" sx={{ color: props.selected ? iconSelectedColor : textColor }}>
              {props.name}
            </Typography>
          }
        />
    </ListItemButton>);
}

export default  NavItem;