import { Box, Toolbar } from "@mui/material";
import Header from "./Header";
import { PropsWithChildren } from "react";
import Footer from "../../components/Footer";

interface BasePageProps{
    title: string
    appBarChildren?: React.ReactNode;
    useLinks?: boolean
}

const BasePage = (props: PropsWithChildren<BasePageProps>) => {
    return (
    <Box  sx={{display: "flex", width: "100%", minHeight: "100%", flexDirection:"column"}}>
    <Header title={props.title}  useLinks={props.useLinks}>{props.appBarChildren}</Header>
    <Box component="main" flexDirection="column" sx={{
        padding: "24px",
        width: '100%', 
        flexGrow: 1,
    }}>
        <Toolbar/>
        
        <Box  display="flex" width="100%" m="0">
        {props.children}
        </Box>
        
        
    </Box>
    <Footer useLinks={props.useLinks}/>
</Box>
);
}

export default BasePage;