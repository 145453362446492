import { Box, Link, Stack, useTheme } from "@mui/material";
import { useContext } from "react";
import { CookieDialogContext, CookieDialogContextType } from "../utils/CookieDialogContext";
import { NavigateFunction, useNavigate } from "react-router-dom";

interface FooterProps{
    useLinks? :boolean
}

function getLink(name: string,href: string, navigate: NavigateFunction,useLinks?:boolean){
    
    if(useLinks){
        return <Link href={href}>{name}</Link>
    }
    return <Link component="button" onClick={() => {navigate(href)}}>{name}</Link>
}

const Footer = (props: FooterProps) => {
    const theme = useTheme();
    const {
        openDialog
      } = useContext(CookieDialogContext) as CookieDialogContextType;
      const navigate = useNavigate();
    return (
    <Box component="footer" width="100%" bgcolor="white" sx={{
        padding: 3,
        borderTop: `1px solid ${theme.palette.divider}`
    }}>
        <Stack direction="row" spacing={3} justifyContent="center">
            {getLink("Privacy Policy","/privacy",navigate,props.useLinks)}
            {getLink("Legal Notice","/legal",navigate,props.useLinks)}
            <Link component="button" onClick={() => {openDialog()}}>Cookie Settings </Link>
        </Stack>
        
    </Box>);
}

export default Footer;