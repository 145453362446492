import { AppBar, styled } from "@mui/material";

const StyledAppBar = styled(AppBar)(({theme}) => 
    ({
        zIndex: theme.zIndex.drawer + 1,
        left: 0,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
    }));

export default StyledAppBar;