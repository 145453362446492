import { createContext } from "react";


export type CookieDialogContextType = {
    open:boolean, 
    openDialog: () => void,
    ignoreBanner: boolean,
    setIgnore: (ignore: boolean) => void
};

export const CookieDialogContext = createContext<CookieDialogContextType | null>(null);