import { useOidc, useOidcFetch } from "@axa-fr/react-oidc";
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Stack, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";

const urlPrefix = '/certification';

interface FileUploadDialogProps {
    open: boolean,
    onClose: () => void
}

function isValidFileName(name: string): boolean {
    if (!name || name.length === 0) {
        return false;
    }
    for (var i = 0; i < name.length; i++) {
        let code = name.charCodeAt(i);
        if (!((code > 47 && code < 58) || // numeric (0-9)
            (code > 64 && code < 91) || // upper alpha (A-Z)
            (code > 96 && code < 123) ||// lower alpha (a-z)
            code === 33 || // !
            code === 45 || // -
            code === 95 || // _
            code === 46 || // .
            code === 42 || // *
            code === 40 || // (
            code === 41 // )
        )) {
            return false;
        }
    }
    return true;
}

const FileUploadDialog = (props: FileUploadDialogProps) => {
    const mounted = useRef(false);
    const [file, setFile] = useState<File | null | undefined>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null | undefined>(null);
    const { renewTokens } = useOidc('default');
    const { fetch: secureFetch } = useOidcFetch(undefined, 'default');

    function getUploadUrl(contentLength: number): Promise<string> {
        const url = process.env.REACT_APP_CERTIFICATION_DOMAIN! + urlPrefix + `/upload?cl=${contentLength}`;
        return secureFetch(url, {
            method: 'GET',
        }).then<any>((response) => {
            if (response.status !== 200) {
                throw new Error();
            }
            return response.json();
        }).then<string>((json) => {
            return json.url!;
        });
    }

    function uploadFile(url: string, file: File): Promise<string> {
        return fetch(url, {
            method: 'PUT',
            body: file
        }).then<string>((response) => {
            if (response.status !== 200) {
                throw new Error();
            }
            return response.headers.get('etag')!;
        });
    }

    function finalizeUpload(etag: string, name: string): Promise<void> {

        const url = process.env.REACT_APP_CERTIFICATION_DOMAIN! + urlPrefix + `/finalize`;
        const formData = new URLSearchParams();
        formData.append("etag", etag);
        formData.append("name", name);
        return secureFetch(url, {
            method: 'POST',
            body: formData
        }).then<void>((response) => {
            if (response.status !== 200) {
                throw new Error();
            }
            return;
        });
    }
    const upload = async () => {
        if (!file) {
            return;
        }
        setLoading(true);
        setError(null);
        try {
            const renamedFile = new File([file as Blob],file!.name.replaceAll(" ", "_"),{type: file!.type})
            if (!isValidFileName(renamedFile.name)) {
                setError('File Name is invalid. Only following characters are allowed: 0-9, a-z, A-Z, !, -, _, ., *, (, ) and whitespace');
                return;
            }
            const uploadUrl = await getUploadUrl(renamedFile.size);
            const etag = await uploadFile(uploadUrl, renamedFile);
            if(!etag){
                throw new Error();
            }
            await finalizeUpload(etag, renamedFile.name);
            await renewTokens();
            close();
        } catch (error) {
            if (!mounted) {
                return;
            }
            setError('Sorry, an unexpected error has occured. Please try again!')
        } finally {
            if (!mounted) {
                return;
            }
            setLoading(false);
        }

    }
    const close = () => {
        setFile(null);
        setError(null);
        props.onClose();
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFile(event.target.files?.item(0));
    }

    useEffect(() => {
        mounted.current = true;
        return () => {
            mounted.current = false;
        }
    }, []);

    return (
        <Dialog onClose={() => { if (!loading) { close(); } }} open={props.open} maxWidth='md' fullWidth={true} scroll={"paper"}>
            <DialogTitle variant="h5">Certification - File Upload</DialogTitle>
            <DialogContent dividers={true}>
                <DialogContentText variant="body1" component="div">
                Please upload a document that verifies your freelancer status. Our team will review your submission promptly to ensure you can access all our services as quickly as possible.
                </DialogContentText>
                <Stack mt={3} mb={3} direction="row" spacing={2} alignItems="center">
                    <Typography mr={3} textAlign="center">{file === null || file === undefined ? "Select File" : file.name}</Typography>
                    <Box>
                        <input
                            disabled={loading}
                            accept="application/pdf"
                            style={{ display: 'none' }}
                            id="contained-button-file"
                            multiple={false}
                            type="file"
                            onChange={handleChange}
                        />
                        <label htmlFor="contained-button-file">
                            <Button disabled={loading} variant="contained" color="primary" component="span">
                                Select File
                            </Button>
                        </label>
                    </Box>
                </Stack>
                {loading && <CircularProgress />}
                {error && <Typography color='error'>{error}</Typography>}
            </DialogContent>
            <DialogActions>
                <Button disabled={loading} onClick={() => { close(); }}>
                    Cancel
                </Button>
                <Button disabled={loading || file === null || file === undefined} variant="contained" onClick={() => { upload(); }}>
                    Upload
                </Button>
            </DialogActions>
        </Dialog>);
}

export default FileUploadDialog;

