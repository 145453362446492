import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useCertification } from "../utils/hooks";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";

const WelcomeDialog = () =>{
    const navigate = useNavigate();
    const [open, setOpen] = useState<boolean>(false);
    const {label} = useCertification();
    useEffect(() => {
        var shouldOpen = label === 'uncertified' || label === 'rejected';
        setOpen(shouldOpen);
        return () => {setOpen(false)}
    },[]);
    return(
        <Dialog open={open} maxWidth='md' fullWidth={true} scroll={"paper"} onClose={() => {setOpen(false)}}>
            <DialogTitle variant="h5">Welcome!</DialogTitle>
            <DialogContent dividers={true}>
            <DialogContentText variant="body1" component="div">
             Before we get started, please upload a document that verifies your freelancer status. Our team will review your submission promptly to ensure you can access all our services as quickly as possible.
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={() => {setOpen(false)}}>
                        Close
                    </Button>
                <Button variant="contained" onClick={() => { 
                    setOpen(false); 
                    navigate('/portal/account')
                    }}>
                    Go to Upload
                </Button>
            </DialogActions>
            </Dialog>
    );
}

export default WelcomeDialog;