import { Box, BoxProps } from "@mui/material";

type ImageProps = {
    alt: string,
    src: string
}

const Image = (props: BoxProps & ImageProps) => {
    return<Box component="img" {...props}/>
}

export default Image;