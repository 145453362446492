import { Box, Breadcrumbs, Link, Stack, Typography } from "@mui/material";
import { PropsWithChildren } from "react";
import { useNavigate } from "react-router-dom";


interface BreadcrumbDefinition{
    name: string
    href?: string
}

interface PortalContentProps{
    title: String
    breadcrumbs: BreadcrumbDefinition[];
}

const BreadcrumbContent = (breadcrumbs: BreadcrumbDefinition[]) => {
    const navigate = useNavigate();
    const children = breadcrumbs.map((value) => {
        if(value.href === undefined){
            return <Typography key={value.name}>{value.name}</Typography>
        }
        return <Link component="button" key={value.name} onClick={() => {navigate(value.href!)}}>{value.name}</Link>
    });
    return (<Breadcrumbs sx={{mb: 3}}>{children}</Breadcrumbs>);
}

const PortalContent = (props: PropsWithChildren<PortalContentProps>) => {
    var header: JSX.Element;
    const title = <Typography variant="h5">{props.title}</Typography>;
    if(props.breadcrumbs.length === 0){
        header= title
    }else {
        header = <Stack>
            {BreadcrumbContent(props.breadcrumbs)}
            <Typography variant="h5">Freelancer Insights</Typography>
            </Stack>
    }
    return(<Box>
            <Box border='none' mb={3}>
                {header}
            </Box>
            {props.children}
        </Box>)
    
}

export default PortalContent;