import { PropsWithChildren, useContext, useEffect } from "react"
import { CookieDialogContext, CookieDialogContextType } from "../utils/CookieDialogContext";

interface CookieIgnoreHandlerProps{
    ignore?: boolean
}

const CookieIgnoreHandler = (props: PropsWithChildren<CookieIgnoreHandlerProps>) => {
    const {
        setIgnore
      } = useContext(CookieDialogContext) as CookieDialogContextType;
    useEffect(() => {
        setIgnore(props.ignore ?? false);
        return () => {
            setIgnore(false);
        }
    },[]);
    return (<>{props.children}</>);
}

export default CookieIgnoreHandler;