import { ButtonBase, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Image from "./Image";

interface HomeButtonProps {
    useLinks?: boolean
}

const HomeButton = (props: HomeButtonProps) => {
    const navigate = useNavigate();
    if (props.useLinks) {
        return (
            <ButtonBase href="/">
                <Image sx={{ m: 1 }} width="46px" src="/apple-touch-icon.png" alt="logo"></Image>
                <Typography color="text.primary" variant="subtitle1">Freelancer Toolbox</Typography>
            </ButtonBase>
        );
    }
    return (
        <ButtonBase onClick={() => navigate('/')}>
            <Image sx={{ m: 1 }} width="46px" src="/apple-touch-icon.png" alt="logo"></Image>
            <Typography color="text.primary" variant="subtitle1">Freelancer Toolbox</Typography>
        </ButtonBase>
    );
}
export default HomeButton;