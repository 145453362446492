import TableauView from "../components/TableauView";
import { useNavigate, useOutlet } from "react-router-dom";
import PortalContent from "../layout/portal/PortalContent";
import { OidcConfiguration } from "@axa-fr/react-oidc";
import { useCertification } from "../utils/hooks";
import { Link, Typography, Zoom } from "@mui/material";

const insightsAuthConfig : OidcConfiguration = {
    authority: process.env.REACT_APP_OIDC_AUTHORITY!,
    client_id: process.env.REACT_APP_OIDC_CLIENT_ID!,
    redirect_uri: window.location.origin +"/portal/tools/freelancer-insights/authentication/callback",
    silent_redirect_uri: window.location.origin + "/portal/tools/freelancer-insights/authentication/silent-callback",
    scope: "openid xid freelancer-insights",
    service_worker_only: false,
}


const FreelancerInsightsPage = () => {
    const outlet = useOutlet();
    const {certified} = useCertification();
    const navigate = useNavigate();
    return(
        <PortalContent title="Freelancer Insights" breadcrumbs={[
            {
                name: "Tools",
                href: "/portal/tools"
            },
            {
                name: "Freelancer Insights"
            }
        ]}>
        {!certified && (<Typography>You are not a certified Freelancer. Please check your certification status on the <Link sx={{verticalAlign: "baseline"}}component="button" onClick={() => {navigate("/portal/account")}}>Account</Link> page.</Typography>)}
        {certified && (outlet ||<TableauView 
        configurationName="freelancer-insights"
        configuration={insightsAuthConfig}
        src='https://eu-west-1a.online.tableau.com/t/piton/views/02_freelancer_insights/FrontPage'
        width='1375'
        height='815'
        hide-tabs toolbar='hidden' 
        />)}
        </PortalContent>
    );
}

export default FreelancerInsightsPage;