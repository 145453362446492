import { LogoutOutlined, Menu, Person } from "@mui/icons-material";
import { Box, ButtonBase, Card, CardContent, CircularProgress, ClickAwayListener, Grid, IconButton, List, ListItemButton, ListItemText, Paper, Popper, Stack, Toolbar, Tooltip, Typography, styled, useMediaQuery, useTheme } from "@mui/material";
import { useState } from "react";
import { drawerWidth } from "../drawer/Drawer";
import { useNavigate } from "react-router-dom";
import { OidcUserStatus, useOidc, useOidcUser } from "@axa-fr/react-oidc";
import StyledAppBar from "../../../components/StyledAppBar";
import HomeButton from "../../../components/HomeButton";

const SizedAppBar = styled(StyledAppBar,{shouldForwardProp: (prop) => prop !== 'open'})<{
    open?: boolean;
  }>(({ theme, open }) =>
({...(open &&{
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
})
}));

interface HeaderProps{
    drawerOpen: boolean
    toggleDrawer: () => void
}

const Header = (props: HeaderProps) => {
    
    
    const { oidcUser, oidcUserLoadingState } = useOidcUser('default');
    const { logout } = useOidc('default');
    const navigate = useNavigate();
    const theme = useTheme();
    const useTemporary = useMediaQuery(theme.breakpoints.down('sm'));
    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    const handleToggle = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <SizedAppBar
            open={props.drawerOpen}
            position="fixed"
            color="inherit"
            elevation={0}
            sx={{
                justifyContent: 'center',
                height:63,
                borderBottom: `1px solid ${theme.palette.divider}`
            }}
        >
            <Toolbar >
                <Box display="flex" flex="1" flexDirection="row" justifyContent="space-between">
                    <Stack spacing={2} direction="row">
                    {!props.drawerOpen && <Box justifyContent="center" alignContent="center"><IconButton onClick={() => {props.toggleDrawer()}}><Menu/></IconButton></Box>}
                       {(!props.drawerOpen && useTemporary) && <HomeButton />}
                       {(!useTemporary)&& <HomeButton />}
                    </Stack>
                    
                    <Box sx={{display:"flex", flexShrink: 0, ml: 0.75, alignItems: "center"}}>
                        <ButtonBase
                            sx={{
                                p: 0.25,
                                bgcolor: open ? 'grey.100' : 'transparent',
                                borderRadius: 1,
                                '&:hover': { bgcolor: 'secondary.lighter' },
                                '&:focus-visible': { outline: `2px solid ${theme.palette.secondary.dark}`, outlineOffset: 2 }
                            }}
                            onClick={handleToggle}
                        >
                            <Stack direction="row" spacing={1.25} alignItems="center" sx={{ p: 0.5 }}>
                                <Person />
                                {(oidcUserLoadingState === OidcUserStatus.Loaded) && <Typography variant="subtitle1" sx={{ textTransform: 'capitalize' }}>
                                    {oidcUser.name}</Typography>}
                                {(oidcUserLoadingState !== OidcUserStatus.Loaded) && <CircularProgress size={24} />}

                            </Stack>
                        </ButtonBase>
                        <Popper
                            anchorEl={anchorEl}
                            placement="bottom-end"
                            open={open}
                            role={undefined}
                            disablePortal
                            popperOptions={{
                                modifiers: [
                                    {
                                        name: 'offset',
                                        options: {
                                            offset: [0, 9]
                                        }
                                    }
                                ]
                            }}
                        >
                            <Paper sx={{ width: 290, minWidth: 240, maxWidth: { xs: 250, md: 290 } }}>
                                <ClickAwayListener onClickAway={handleClose}>
                                    <Card elevation={0} sx={{
                                        border: "none",
                                        borderRadius: 2,
                                        borderColor: theme.palette.divider,
                                        '& pre': {
                                            m: 0,
                                            p: '16px !important',
                                            fontFamily: theme.typography.fontFamily,
                                            fontSize: '0.75rem'
                                        },
                                    }}>
                                        <CardContent sx={{ px: 2.5, pt: 3 }}>
                                            <Grid container justifyContent="space-between" alignItems="center">
                                                <Grid item>
                                                    <Stack direction="row" spacing={1.25} alignItems="center">
                                                        <Person sx={{ width: 32, height: 32 }} />
                                                        <Stack>
                                                            {(oidcUserLoadingState === OidcUserStatus.Loaded) && <Typography variant="h6">{oidcUser.name}</Typography>}
                                                            {(oidcUserLoadingState !== OidcUserStatus.Loaded) && <CircularProgress size={24} />}
                                                            <Typography variant="body2" color="text.secondary">
                                                                Freelancer
                                                            </Typography>
                                                        </Stack>
                                                    </Stack>
                                                </Grid>
                                                <Grid item>
                                                    <Tooltip title="Logout">
                                                        <IconButton onClick={() => logout("/")} size="large" sx={{ color: 'text.primary' }}>
                                                            <LogoutOutlined />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                            <List component="nav" sx={{ p: 0, '& .MuiListItemIcon-root': { minWidth: 32 } }}>
                                                <ListItemButton onClick={(event) => { navigate('/portal/account') }}>
                                                    <ListItemText primary="View Account" />
                                                </ListItemButton>
                                                <ListItemButton onClick={(event) => { navigate('/privacy') }}>
                                                    <ListItemText primary="Privacy Policy" />
                                                </ListItemButton>
                                                <ListItemButton onClick={(event) => { navigate('/legal') }}>
                                                    <ListItemText primary="Legal Notice" />
                                                </ListItemButton>
                                            </List>
                                        </Box>
                                    </Card>
                                </ClickAwayListener>
                            </Paper>
                        </Popper>
                    </Box>
                </Box>
            </Toolbar>
        </SizedAppBar>
    );
}

export default Header;