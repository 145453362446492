import Button from '@mui/material/Button';
import { Box, Grid, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import { StringMap, useOidc } from "@axa-fr/react-oidc";
import BasePage from "../layout/base/BasePage";
import Image from "../components/Image";
import { useNavigate } from "react-router-dom";



const HomePage = () => {
  const navigate = useNavigate();
  const { login, isAuthenticated } = useOidc('default');
  const theme = useTheme();
  const asRow = useMediaQuery(theme.breakpoints.up('sm'));

  const handleLogin = (extras?: StringMap) => {
    if(isAuthenticated){
      navigate("/portal/tools")
    }else {
      login("/portal/tools", extras);
    }
  }

  return (
    <BasePage appBarChildren={<Button variant="contained" onClick={() => handleLogin()}>{isAuthenticated ? "Go to toolbox" : "Login"}</Button>} title="Welcome">
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12} sm={8}>
          <Stack spacing={5}>
            <Typography variant="h1">Welcome to the Freelancer Toolbox</Typography>
            <Typography>Freelancers face a myriad of tasks—from securing new projects and managing CVs to analyzing market trends. Often, these responsibilities detract from their actual work, causing frustration. The Freelancer Toolbox is designed to streamline these processes, enabling you to focus on what you do best.</Typography>
            <Stack spacing={3} justifyContent="end"direction="row">
            <Button variant="outlined" target="_blank" rel="norefferrer" href='https://outlook.office.com/bookwithme/user/9cf31257ba864e4b860bcc58a2950d85@pitontech.onmicrosoft.com/meetingtype/YD027s1hZkaMLGI6QmcTJg2?anonymous&ep=mcard'>Schedule free session</Button>
            {!isAuthenticated && <Button variant="contained" onClick={() => handleLogin({register:'true'})}>Register</Button>}
            </Stack>
            <Box display="flex" justifyContent="end" alignItems="end">
            </Box>
          </Stack>

        </Grid>
        <Grid item xs={12} sm={4} sx={{ display: "flex", flexDirection: "row", justifyContent: asRow? "flex-end" : "center" }}>
          
          <Image sx={{
            padding: asRow ? '0' : '40px',
            objectFit:"cover"
            }} maxWidth="100%" alt='Logo' src="/android-chrome-512x512.png"></Image>
          
          
        </Grid>
      </Grid>
    </BasePage>);

};

export default HomePage;