import { Link, List, ListItemText, Stack, Typography } from "@mui/material";
import BasePage from "../layout/base/BasePage";
import CookieIgnoreHandler from "../components/CookieIgnoreHandler";
import { useNavigate } from "react-router-dom";


const PrivacyPage = () => {
  const navigate = useNavigate();
  return (
    <CookieIgnoreHandler ignore={true}>
      <BasePage title="Privacy Policy">
        <Stack spacing={3}>
          <Stack spacing={1}>
            <Typography variant="h5">Privacy Policy</Typography>

            <Typography variant="h5">§ 1 Information on the collection of personal data</Typography>
            <Typography>(1) In the following, we provide information about the collection of personal data when using our website. Personal data is all data that can be related to you personally, e.g. name, address, email addresses, user behaviour. In doing so, we would like to inform you about our processing procedures and at the same time fulfil our legal obligations, in particular those arising from the EU General Data Protection Regulation (GDPR).</Typography>
            <Typography>(2) PITON GmbH, Friedensstraße 13-15, 73614 Schorndorf, info@piton-tech.de (see our <Link component="button" onClick={() => navigate('/legal')}>legal notice</Link>) is the controller pursuant to Art. 4 (7) of the EU General Data Protection Regulation (GDPR).</Typography>
            <Typography>(3) When you contact us by e-mail or via a contact form, the data you provide (your e-mail address, your name and telephone number if applicable) will be stored by us in order to answer your questions. If the enquiry is assigned to a contract, we delete the data arising in this context after the contract period, otherwise after the storage is no longer required, or restrict the processing if there are statutory retention obligations.</Typography>
            <Typography>If you wish to contact us via the contact form provided on our website, we will collect the following data from you:</Typography>
            <List sx={{ listStyle: "disc", pl: 4 }}>
              <ListItemText sx={{ display: "list-item" }}>Salutation (optional)</ListItemText>
              <ListItemText sx={{ display: "list-item" }}>Name</ListItemText>
              <ListItemText sx={{ display: "list-item" }}>E-mail address</ListItemText>
              <ListItemText sx={{ display: "list-item" }}>Your message to us</ListItemText>
            </List>
            <Typography>We process this data in order to be able to respond to your enquiry. We store the data collected via the contact form and the communication to answer your enquiry for a period not exceeding 30 days in case there are any queries.</Typography>
            <Typography>Insofar as your enquiry relates to an existing contractual relationship with you or you are interested in concluding a contract, for example the purchase of one of our products, and approach us, the aforementioned data processing is carried out on the legal basis of Art. 6 para. 1 sentence 1 lit. b GDPR (contract initiation and contract fulfilment). Otherwise, the data processing is carried out on the legal basis of Art. 6 para. 1 sentence 1 lit. GDPR (balancing of interests, our interest is to be able to answer your enquiry with the information relevant to you).</Typography>
            <Typography>(4) If we use contracted service providers for individual functions of our offer or wish to use your data for advertising purposes, we will always carefully select and monitor these service providers and inform you in detail below about the respective processes. In doing so, we also specify the defined criteria for the storage period.</Typography>
          </Stack>

          <Stack spacing={1}>
            <Typography variant="h5">§ 2 Your rights</Typography>
            <Typography>(1) You have the following rights with respect to the personal data concerning you:</Typography>
            <List sx={{ listStyle: "disc", pl: 4 }}>
              <ListItemText sx={{ display: "list-item" }}>Right to information</ListItemText>
              <ListItemText sx={{ display: "list-item" }}>Right to correction or deletion</ListItemText>
              <ListItemText sx={{ display: "list-item" }}>Right to restriction of processing</ListItemText>
              <ListItemText sx={{ display: "list-item" }}>Right to object to processing</ListItemText>
              <ListItemText sx={{ display: "list-item" }}>Right to data portability</ListItemText>
            </List>
            <Typography>(2) You also have the right to complain to a data protection supervisory authority about our processing of your personal data.</Typography>
          </Stack>

          <Stack spacing={1}>
            <Typography variant="h5">§ 3 Processing of personal data when visiting our website</Typography>
            <Typography>When using the website for information purposes, i.e. simply viewing it without registering and without transmitting any other information to us, we process the personal data that your browser transmits to our server. The data described below is technically necessary for us to display our website to you and to ensure stability and security and must therefore be processed by us. The legal basis is Art. 6 para. 1 sentence 1 lit. f GDPR:</Typography>
            <List sx={{ listStyle: "disc", pl: 4 }}>
              <ListItemText sx={{ display: "list-item" }}>IP address</ListItemText>
              <ListItemText sx={{ display: "list-item" }}>Date and time of the enquiry</ListItemText>
              <ListItemText sx={{ display: "list-item" }}>Time zone difference to Greenwich Mean Time (GMT)</ListItemText>
              <ListItemText sx={{ display: "list-item" }}>Content of the request (page visited)</ListItemText>
              <ListItemText sx={{ display: "list-item" }}>Access status/HTTP status code</ListItemText>
              <ListItemText sx={{ display: "list-item" }}>Amount of data transferred in each case</ListItemText>
              <ListItemText sx={{ display: "list-item" }}>Previously visited page</ListItemText>
              <ListItemText sx={{ display: "list-item" }}>Browser</ListItemText>
              <ListItemText sx={{ display: "list-item" }}>Operating system</ListItemText>
              <ListItemText sx={{ display: "list-item" }}>Language and version of the browser software</ListItemText>
            </List>
          </Stack>

          <Stack spacing={1}>
            <Typography variant="h5">§ 4	Further functions and offers of our website</Typography>
            <Typography>(1) In addition to the purely informational use of our website, we offer various services that you can use if you are interested and use other common functions to analyse or market our offers, which are presented in more detail below. For this purpose, you must generally provide additional personal data or we process such additional data that we use to provide the respective service. The aforementioned data processing principles apply to all data processing purposes described here.</Typography>
            <Typography>(2) In some cases, we use external service providers to process your data. These have been carefully selected by us, are bound by our instructions and are regularly monitored.</Typography>
            <Typography>(3) Furthermore, we may pass on your personal data to third parties if we offer participation in promotions, competitions, contracts or similar services together with partners. Depending on the service, your data may also be collected by the partners on their own responsibility. You will receive more detailed information when you provide your data or below in the description of the respective offers.</Typography>
            <Typography>(4) If our service providers or partners are based in a country outside the European Economic Area (EEA), we will inform you of the consequences of this circumstance in the description of the offer.</Typography>
          </Stack>
          
          <Stack spacing={1}>
            <Typography variant="h5">§ 5 Objection or revocation against the processing of your data</Typography>
            <Typography>(1) If you have given your consent to the processing of your data, you can revoke it at any time. Such a revocation affects the permissibility of the processing of your personal data after you have given it to us. The permissibility of the processing of your data up to the time of your revocation remains unaffected.</Typography>
            <Typography>(2) Insofar as we base the processing of your personal data on the balancing of interests, you can object to the processing. This is the case if, in particular, the processing is not necessary for the fulfilment of a contract with you, which is described by us in the following description of the functions. When exercising such an objection, we ask you to explain the reasons why we should not process your personal data in the way we do. In the event of your justified objection, we will examine the situation and will either discontinue or adapt the data processing or show you our compelling reasons worthy of protection on the basis of which we will continue the processing.</Typography>
            <Typography>(3) Of course, you can object to the processing of your personal data for advertising and data analysis purposes at any time. The best way to exercise your objection to advertising is to contact us using the contact details given above.</Typography>
          </Stack>

          <Stack spacing={1}>
            <Typography variant="h5">§ 6 Processing of data from your end devices ("Cookie Policy")</Typography>
            <Typography>(1) In addition to the above-mentioned data, we use technical aids for various functions when you use our website, in particular cookies, which can be stored on your end device. When you access our website and at any time thereafter, you have the choice of whether you generally allow cookies to be set or which individual additional functions you would like to select. You can make changes in your browser settings or via our Consent Manager. In the following, we first describe cookies from a technical point of view (2) before going into more detail about your individual choices by describing technically necessary cookies (3) and cookies that you can voluntarily select or deselect (4).</Typography>
            <Typography>(2) Cookies are text files or information in a database that are stored on your hard drive and assigned to the browser you are using so that certain information can flow to the location that sets the cookie. Cookies cannot execute programmes or transfer viruses to your computer, but are primarily used to make the website faster and more user-friendly. This website uses the following types of cookies, whose function and legal basis are explained below:</Typography>
            <List sx={{ listStyle: "disc", pl: 4 }}>
              <ListItemText sx={{ display: "list-item" }}>Transient cookies: Such cookies, especially session cookies, are automatically deleted when the browser is closed or by logging out. They contain a so-called session ID. This allows various requests from your browser to be assigned to the shared session and your computer can be recognised when you return to our website.</ListItemText>
              <ListItemText sx={{ display: "list-item" }}>Persistent cookies: These are automatically deleted after a specified period, which varies depending on the cookie. You can view the cookies set and the duration at any time in your browser settings and delete the cookies manually.</ListItemText>
            </List>
            <Typography>(3) Mandatory functions that are technically necessary to display the website: The technical structure of the website requires us to use technologies, in particular cookies. Without these technologies, our website cannot be displayed (completely correctly) or the support functions could not be enabled. These are basically transient cookies that are deleted at the end of your visit to the website, at the latest when you close your browser. You cannot deselect these cookies if you wish to use our website. The individual cookies can be seen in the Consent Manager. The legal basis for this processing is Art. 6 para. 1 sentence 1 lit. f GDPR.</Typography>
            <Typography>(4) Optional cookies if you give your consent: We only set various cookies with your consent, which you can select on your first visit to our website via the so-called cookie consent tool. The functions are only activated if you give your consent and can be used in particular to enable us to analyse and improve visits to our website, to make it easier for you to use our website via different browsers or end devices, to recognise you when you visit us again or to place advertising (possibly also to tailor advertising to your interests, measure the effectiveness of advertisements or show interest-based advertising). The legal basis for this processing is Art. 6 para. 1 sentence 1 lit. a GDPR. You can withdraw your consent at any time without this affecting the lawfulness of processing up to the point of withdrawal. The functions we use, which you can select and revoke individually via the Consent Manager, are described below.</Typography>
          </Stack>

          <Stack spacing={1}>
            <Typography variant="h5">§ 7	Special forms of utilisation</Typography>
            <Typography variant="h5">Use of our portal</Typography>
            <Typography>(1) If you wish to use our portal, you must register by entering your e-mail address, a password of your choice and a user name of your choice. There is no obligation to use a clear name; pseudonymous use is possible. The provision of the aforementioned data is mandatory; you can provide all other information voluntarily by using our portal. We use the so-called double opt-in procedure for this service, i.e. you will receive an e-mail in which you must confirm that you are the owner of the e-mail address provided and that you wish to receive the notifications. You can unsubscribe from the notifications at any time, e.g. by clicking on the link in the email or using the contact details provided. We will store the data you provide, the time of your registration for the service and your IP address until you unsubscribe from the notification service.</Typography>
            <Typography>(2) When you use our portal, we store your data required for the fulfilment of the contract, including details of the payment method, until you finally delete your access. Furthermore, we store the voluntary data you provide for the duration of your use of the portal, unless you delete it beforehand. You can manage and change all information in the protected customer area. The legal basis is Art. 6 para. 1 sentence 1 lit. b GDPR.</Typography>
            <Typography>(3) If you use the portal, your data may become accessible to other portal participants in accordance with the contractual service. Members who are not logged in will not receive any information about you. Your user name and photo are visible to all registered members, regardless of whether you have shared them. In contrast, your entire profile with the data you have shared is visible to all members who have confirmed you as a personal contact. If you make content accessible to your personal contacts that you do not send by means of a private message, this content will be visible to third parties if your personal contact has authorised it. If you post content in public groups, this content will be visible to all registered members of the portal.</Typography>
            <Typography>(4) To prevent unauthorised access by third parties to your personal data, in particular financial data, the connection is encrypted using TLS technology.</Typography>
          </Stack>

          <Stack spacing={1}>
            <Typography variant="h5">§ 8	Newsletter</Typography>
            <Typography variant="h5">1. Newsletter</Typography>
            <Typography>(1) You can subscribe to our newsletter, with which we inform you about our current interesting offers, by giving your consent. The advertised goods and services are named in the declaration of consent.</Typography>
            <Typography>(2) We use the so-called double opt-in procedure to subscribe to our newsletter. This means that after you have registered, we will send you an e-mail to the e-mail address provided, in which we ask you to confirm that you are the owner of the e-mail address provided and that you wish to receive the notifications. If you do not confirm your registration within 24 hours, your information will be blocked and automatically deleted after one month. In addition, we store the IP addresses you use and the times of registration and confirmation. The purpose of this procedure is to be able to prove your registration and, if necessary, to clarify any possible misuse of your personal data.</Typography>
            <Typography>(3) The only mandatory information for sending the newsletter is your e-mail address. The provision of further, separately marked data is voluntary and is used to address you personally. After your confirmation, we will save your e-mail address for the purpose of sending you the newsletter. The legal basis is Art. 6 para. 1 sentence 1 lit. a GDPR.</Typography>
            <Typography>(4) You can revoke your consent to the sending of the newsletter at any time and unsubscribe from the newsletter. You can declare your cancellation by clicking on the link provided in every newsletter email, by sending an email to info@piton-tech.de or by sending a message to the contact details given in the legal notice.</Typography>
            <Typography>(5) In addition, you can also give your consent for us to evaluate your user behaviour when sending the newsletter. For this analysis, the emails sent contain so-called web beacons or tracking pixels, which are single-pixel image files stored on our website. For the analyses, we link the data mentioned above in § 3 and the web beacons with your email address and an individual ID. The information about your user behaviour is collected exclusively in pseudonymised form, i.e. the IDs are not linked to your other personal data, and direct personal identification is excluded. You can object to this tracking at any time by clicking on the separate link provided in each email or by informing us via another contact method as described above. The information will be stored for as long as you are subscribed to the newsletter. After you unsubscribe, we store the data purely statistically and anonymously. Such tracking is also not possible if you have deactivated the display of images in your e-mail programme by default. In this case, the newsletter will not be displayed in full and you may not be able to use all the functions. If you display the images manually, the above-mentioned tracking will take place.</Typography>
            
            <Typography variant="h5">2. Deployment and use of Brevo</Typography>
            <Typography>We use the service Brevo , whose provider is Sendinblue GmbH, Köpenicker Straße 126, 10179 Berlin, Germany, to send newsletters.</Typography>
            <Typography>Brevo's service enables, among other things, the organisation and analysis of the sending of newsletters. The data you enter when subscribing to the newsletter is stored on Brevo's servers located in Germany. The e-mails sent to Brevo contain so-called web beacons, which connect to Brevo's servers in Germany when the e-mail is opened. In this way, it can be determined whether a newsletter message has been opened and which links, if any, have been clicked on. The technical information collected (such as time of access, IP address, browser type and operating system) cannot be assigned to the respective newsletter recipient and is used exclusively for the statistical analysis of newsletter campaigns. The results of the analyses can be used to better adapt future newsletters to the interests of the recipients. If you do not agree to this, you must simply unsubscribe from the newsletter. For this purpose, we provide a corresponding link in every newsletter message. You also have the option of unsubscribing from the newsletter on our website.</Typography>
            <Typography>The legal basis for data processing is Art. 6 para. 1 sentence 1 lit. a GDPR. You can revoke your consent to the sending of the newsletter at any time and unsubscribe from the newsletter. You can declare your cancellation by clicking on the link provided in every newsletter email.</Typography>
            <Typography>By concluding a so-called "Data Processing Agreement", we have obliged Brevo to protect our customers' data and not to pass it on to third parties.</Typography>
            <Typography>Further information on the purpose and scope of data collection and its processing can be found in Brevo's privacy policy at <Link target="_blank" rel="norefferrer" href="https://www.brevo.com/de/legal/privacypolicy/">https://www.brevo.com/de/legal/privacypolicy/</Link> and the anti-spam policy at <Link target="_blank" rel="norefferrer" href="https://www.brevo.com/de/legal/antispampolicy/">https://www.brevo.com/de/legal/antispampolicy/</Link>.</Typography>
          </Stack>

          <Stack spacing={1}>
            <Typography variant="h5">§ 9	Web Analytics</Typography>
            <Typography variant="h5">Use of Google Analytics, Google Tag Manager</Typography>
            <Typography>(1) This website uses Google Analytics, a web tracking service provided by Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Ireland ("Google"). The purpose of our use of the tool is to enable us to analyse your user interactions on websites and in apps and to use the statistics and reports obtained to improve our offering and make it more interesting for you as a user.</Typography>
            <Typography>(2) We primarily record the interactions between you as a user of the website and our website using cookies, device/browser data, IP addresses and website or app activities. In Google Analytics, your IP addresses are also recorded to ensure the security of the service and to provide us as the website operator with information about the country, region or location from which the respective user comes (so-called "IP location determination"). For your protection, however, we naturally use the anonymisation function ("IP masking"), i.e. Google truncates the IP addresses by the last octet within the EU/EEA.</Typography>
            <Typography>(3) Google acts as a processor and we have concluded a corresponding contract with Google. The information generated by the cookie and the (usually shortened) IP addresses about your use of this website are usually transferred to a Google server in the USA and processed there. For these cases, Google has, according to its own information, imposed a standard that corresponds to the former EU-US Privacy Shield and has promised to comply with applicable data protection laws when transferring data internationally. We have also agreed so-called standard contractual clauses with Google, the purpose of which is to maintain an appropriate level of data protection in the third country.</Typography>
            <Typography>(4) The legal basis for the collection and further processing of the information (which takes place for a maximum of 14 months) is your consent (Art. 6 para. 1 sentence 1 lit. a GDPR). You can withdraw your consent at any time without affecting the lawfulness of processing based on consent before its withdrawal. In apps, you can reset the advertising ID in the Android or iOS settings. The easiest way to revoke your consent is via our Consent Manager or by installing the Google browser add-on, which can be accessed via the following link: <Link target="_blank" rel="norefferrer" href="https://tools.google.com/dlpage/gaoptout?hl=de/">https://tools.google.com/dlpage/gaoptout?hl=de/</Link>.</Typography>
            <Typography>(5) You can find more information on the scope of services provided by Google Analytics at <Link target="_blank" rel="norefferrer" href="https://marketingplatform.google.com/about/analytics/terms/de/">https://marketingplatform.google.com/about/analytics/terms/de/</Link>. Google provides information on data processing when using Google Analytics at the following link: <Link target="_blank" rel="norefferrer" href="https://support.google.com/analytics/answer/6004245?hl=de/">https://support.google.com/analytics/answer/6004245?hl=de/</Link>. General information on data processing, which according to Google should also apply to Google Analytics, can be found in Google's privacy policy at <Link target="_blank" rel="norefferrer" href="www.google.de/intl/de/policies/privacy/">www.google.de/intl/de/policies/privacy/</Link>.</Typography>
            <Typography>(6) 	This website also uses Google Tag Manager, a service that allows website tags to be managed via an interface. This does not set cookies or collect personal data. However, data may still be collected because other tags are triggered by the service. However, the Google Tag Manager does not access this data. In the event that deactivation has been carried out at the domain or cookie level, this deactivation remains in place for all tracking tags.</Typography>
            <Typography>Information about Google Tag Manager: <Link target="_blank" rel="norefferrer" href="https://www.google.de/tagmanager/use-policy.html">https://www.google.de/tagmanager/use-policy.html</Link>.</Typography>
          </Stack>

          <Stack spacing={1}>
            <Typography variant="h5">§ 10 External Hosting/Content Delivery Networks (CDN)</Typography>
            <Typography>This website is hosted by an external service provider</Typography>
            <Typography>DigitalOcean, LLC (hereinafter DigitalOcean)<br/>101 Avenue of the Americas<br/>10th Floor<br/>New York, NY 10013, United States of America.</Typography>
            <Typography>DigitalOcean is the operator of a so-called cloud platform for virtual servers, which we use as a hosting platform. The personal data collected on this website is stored on the servers of the external service provider, see the list in Section 3 (1). Your data will only be processed by our external service provider if this is necessary to fulfil its contractual obligations and if it follows our instructions regarding your data.</Typography>
            <Typography>DigitalOcean has stated that it has adopted a standard equivalent to the former EU-US Privacy Shield and has undertaken to comply with applicable data protection laws when transferring data internationally. DigitalOcean participates in the EU-US Data Privacy Framework. Further information can be found at <Link target="_blank" rel="norefferrer" href="https://commission.europa.eu/document/fa09cbad-dd7d-4684-ae60-be03fcb0fddf_en">"https://commission.europa.eu/document/fa09cbad-dd7d-4684-ae60-be03fcb0fddf_en"</Link>.</Typography>
            <Typography>We have also agreed so-called standard contractual clauses with DigitalOcean, the purpose of which is to maintain an adequate level of data protection in the third country. Further information on this can be found at <Link target="_blank" rel="norefferrer" href="https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de">"https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de"</Link>.</Typography>
            <Typography>You can find DigitalOcean's privacy policy at <Link target="_blank" rel="norefferrer" href="https://www.digitalocean.com/legal/privacy-policy">https://www.digitalocean.com/legal/privacy-policy</Link>.</Typography>
            <Typography>As the external service provider acts on our behalf with regard to the collection, processing and use of personal data, we have concluded a contract for commissioned data processing with the service provider to implement the requirements of Art. 28 GDPR.</Typography>
            <Typography>The legal basis for the use of the external service provider is Art. 6 para. 1 sentence 1 lit. b and f GDPR.</Typography>
          </Stack>

          <Stack spacing={1}>
            <Typography variant="h5">§ 11	Social Media</Typography>

            <Typography variant="h5">1. Use of social media plug-ins</Typography>
            <Typography>(1) We currently use the following social media plug-ins: LinkedIn, which are only loaded if you have previously activated the function by giving your consent. We offer you the opportunity to interact with the social networks and other users via the plug-ins. The legal basis for the use of the plug-ins is Art. 6 para. 1 sentence 1 lit. a GDPR, i.e. the integration only takes place with your consent.</Typography>
            <Typography>(2) The plug-in provider stores the data collected about you as usage profiles and uses these for the purposes of advertising, market research and/or customising its website. Such an evaluation is carried out in particular (even for users who are not logged in) to display customised advertising and to inform other users of the social network about your activities on our website. You have the right to object to the creation of these user profiles, whereby you must contact the respective plug-in provider to exercise this right. Data is passed on regardless of whether you have an account with the plug-in provider and are logged in there. If you are logged in with the plug-in provider, your data collected by us will be assigned directly to your existing account with the plug-in provider. If you press the activated button and, for example, link the page, the plug-in provider also saves this information in your user account and shares it publicly with your contacts. We recommend that you log out regularly after using a social network, but especially before activating the button, as this will prevent you from being assigned to your profile with the plug-in provider.</Typography>
            <Typography>(3) The information collected is stored on the provider's servers, in the case of international providers also outside Europe. For these cases, the provider has, according to its own information, imposed a standard that corresponds to the former EU-US Privacy Shield and has promised to comply with applicable data protection laws when transferring data internationally. We have also agreed so-called standard data protection clauses with the providers, the purpose of which is to maintain an appropriate level of data protection in the third country.</Typography>
            <Typography>(4) You can withdraw your consent at any time without this affecting the lawfulness of the processing up to the point of withdrawal. The easiest way to revoke your consent is via our Consent Manager or via the functions of the social media providers.</Typography>
            <Typography>(5) Further information on the purpose and scope of data collection and its processing by the plug-in provider can be found in the data protection declarations of these providers provided below. There you will also find further information on your rights in this regard and setting options to protect your privacy. Addresses of the respective plug-in providers and URL of the respective data protection notices:</Typography>
            <Typography>LinkedIn Corporation, 2029 Stierlin Court, Mountain View, California 94043, USA;<Link target="_blank" rel="norefferrer" href="https://www.linkedin.com/legal/privacy-policy">https://www.linkedin.com/legal/privacy-policy</Link>.</Typography>

            <Typography variant="h5">2. Our presence in social networks</Typography>
            <Typography>(1) We have a presence on social media platforms. We operate this presence with the following provider:	LinkedIn Corporation, 2029 Stierlin Court, Mountain View, California 94043, USA; <Link target="_blank" rel="norefferrer" href="https://www.linkedin.com/legal/privacy-policy">https://www.linkedin.com/legal/privacy-policy</Link>.</Typography>
            <Typography>(2) We use the technical platform and services of the provider 	for these information services. We would like to point out that you use our presence on social media platforms and their functions on your own responsibility. This applies in particular to the use of interactive functions (e.g. commenting, sharing, rating). When you visit our website, the provider of the social media platform collects your IP address and other information that is stored on your device in the form of cookies. This information is used to provide us, as the operator of the accounts, with statistical information about the interaction with us.</Typography>
            <Typography>(3) The data collected about you in this context is processed by the platforms and may be transferred to countries outside the European Union, in particular the USA. According to their own information, all of the aforementioned providers maintain an appropriate level of data protection that corresponds to that of the former EU-US Privacy Shield and we have concluded the standard data protection clauses with the companies. We do not know how the social media platforms use the data from your visit to our account and interaction with our posts for their own purposes, how long this data is stored and whether data is passed on to third parties. Data processing may differ depending on whether you are registered and logged in to the social network or whether you visit the site as a non-registered and/or non-logged-in user. When you access a post or the account, the IP address assigned to your end device is transmitted to the provider of the social media platform. If you are currently logged in as a user, a cookie on your device can be used to track how you have moved around the network. Buttons integrated into websites enable the platforms to record your visits to these websites and assign them to your respective profile. This data can be used to offer you customised content or advertising. If you want to avoid this, you should log out or deactivate the "stay logged in" function, delete the cookies on your device and restart your browser.</Typography>
            <Typography>(4) As the provider of the information service, we also only process the data from your use of our service that you provide to us and that require interaction. For example, if you ask a question that we can only answer by email, we will store your information in accordance with the general principles of our data processing, which we describe in this privacy policy. The 	legal basis for the processing of your data on the social media platform is Art. 6 para. 1 sentence 1 lit. f GDPR.</Typography>
            <Typography>(5) To exercise your rights as a data subject, you can contact us or the provider of the social media platform. If one party is not responsible for responding or must receive the information from the other party, we or the provider will then forward your enquiry to the respective partner. Please contact the operator of the social media platform directly for questions about profiling and the processing of your data when using the website. If you have any questions about the processing of your interaction with us on our site, please write to the contact details provided by us above.</Typography>
            <Typography>(6) What information the social media platform receives and how it is used is described by the providers in their privacy policies (see link in the table above). There you will also find information about contact options and the setting options for adverts. Further information on social networks and how you can protect your data can also be found at <Link target="_blank" rel="norefferrer" href="www.youngdata.de">www.youngdata.de</Link>.</Typography>

            <Typography variant="h5">3. Integration of YouTube videos</Typography>
            <Typography>(1) We have integrated YouTube videos into our online offering, which are stored on <Link target="_blank" rel="norefferrer" href="https://www.youtube.com">https://www.youtube.com</Link> and can be played directly from our website. [These are all integrated in "extended data protection mode", i.e. no data about you as a user is transferred to YouTube if you do not play the videos. Only when you play the videos will the data mentioned in paragraph 2 be transmitted. We have no influence on this data transfer]. The legal basis for the display of the videos is Art. 6 para. 1 sentence 1 lit. a GDPR, i.e. the integration only takes place with your consent.</Typography>
            <Typography>(2) By visiting the website, YouTube receives the information that you have accessed the corresponding subpage of our website. In addition, the above-mentioned basic data such as IP address and timestamp are transmitted. This occurs regardless of whether YouTube provides a user account through which you are logged in or whether no user account exists. If you are logged in to Google, your data will be assigned directly to your account. If you do not wish your data to be associated with your YouTube profile, you must log out before activating the button. YouTube stores your data as usage profiles and uses them for the purposes of advertising, market research and/or customising its website. Such an evaluation is carried out in particular (even for users who are not logged in) to provide customised advertising and to inform other users of the social network about your activities on our website. You have the right to object to the creation of these user profiles, whereby you must contact YouTube to exercise this right.</Typography>
            <Typography>(3) The information collected is stored on Google servers, including in the USA. In these cases, the provider has, according to its own information, imposed a standard that corresponds to the former EU-US Privacy Shield and has promised to comply with applicable data protection laws when transferring data internationally. We have also agreed so-called standard data protection clauses with Google, the purpose of which is to maintain an appropriate level of data protection in the third country.</Typography>
            <Typography>(4) Further information on the purpose and scope of data collection and its processing by YouTube can be found in the privacy policy. There you will also find further information on your rights and setting options to protect your privacy: <Link target="_blank" rel="norefferrer" href="www.google.de/intl/de/policies/privacy">www.google.de/intl/de/policies/privacy</Link>.</Typography>

            <Typography variant="h5">4. Integration of Google Maps</Typography>
            <Typography>(1) We use the Google Maps service on this website. This allows us to display interactive maps directly on the website and enables you to conveniently use the map function. The legal basis for the use of the maps is Art. 6 para. 1 sentence 1 lit. a GDPR, i.e. the integration only takes place with your consent.</Typography>
            <Typography>(2) By visiting the website, Google receives the information that you have accessed the corresponding subpage of our website. In addition, the above-mentioned basic data such as IP address and time stamp are transmitted. This occurs regardless of whether Google provides a user account through which you are logged in or whether no user account exists. If you are logged in to Google, your data will be assigned directly to your account. If you do not wish your data to be associated with your Google profile, you must log out before activating the button. Google stores your data as usage profiles and uses them for the purposes of advertising, market research and/or customising its website. Such an analysis is carried out in particular (even for users who are not logged in) to provide customised advertising and to inform other users of the social network about your activities on our website. You have the right to object to the creation of these user profiles, whereby you must contact Google to exercise this right.</Typography>
            <Typography>(3) The information collected is stored on Google servers, including in the USA. In these cases, the provider has, according to its own information, imposed a standard that corresponds to the former EU-US Privacy Shield and has promised to comply with applicable data protection laws when transferring data internationally. We have also agreed so-called standard data protection clauses with Google, the purpose of which is to maintain an appropriate level of data protection in the third country.</Typography>
            <Typography>(4) Further information on the purpose and scope of data collection and its processing by the plug-in provider can be found in the provider's privacy policy. There you will also find further information on your rights in this regard and setting options to protect your privacy: <Link target="_blank" rel="norefferrer" href="www.google.de/intl/de/policies/privacy">www.google.de/intl/de/policies/privacy</Link>.</Typography>
          </Stack>

          <Stack spacing={1}>
            <Typography variant="h5">§ 12	Online advertising</Typography>
            <Typography variant="h5">Use of Google Ads</Typography>
            <Typography>(1) We use Google Ads to draw attention to our offers with the help of adverts. If you access our website via a Google advert, Google Ads will store a cookie on your device. The legal basis for the processing of your data is Art. 6 para. 1 sentence 1 lit. a GDPR, i.e. the integration only takes place with your consent.</Typography>
            <Typography>(2) The advertising material is delivered by Google via so-called "ad servers". For this purpose, we and other websites use so-called ad server cookies, through which certain parameters for measuring success, such as the display of adverts or clicks by users, can be measured. We can obtain information about the success of our advertising campaigns via the Google Ads cookies stored on our website. These cookies are not intended to identify you personally. The unique cookie ID, number of ad impressions per placement (frequency), last impression (relevant for post-view conversions) and opt-out information (marking that a user no longer wishes to be addressed) are usually stored as analysis values for this cookie.</Typography>
            <Typography>(3) The cookies set by Google enable Google to recognise your internet browser. If a user visits certain pages of an Ads customer's website and the cookie stored on their computer has not yet expired, Google and the customer can recognise that the user clicked on the ad and was redirected to this page. A different cookie is assigned to each Ads customer so that the cookies cannot be tracked via the websites of other Ads customers. By integrating Google Ads, Google receives the information that you have called up the corresponding part of our website or clicked on an advert from us. If you are registered with a Google service, Google can assign the visit to your account. Even if you are not registered with Google or have not logged in, there is a possibility that the provider will find out your IP address and store it.</Typography>
            <Typography>(4) Due to the marketing tools used, your browser automatically establishes a direct connection with the Google server. We ourselves do not independently collect personal data in the aforementioned advertising measures, but only provide Google with the opportunity to collect the data. We only receive statistical analyses from Google, which provide information on which advertisements were clicked on how often and at what prices. We do not receive any further data from the use of advertising material; in particular, we cannot identify users on the basis of this information.</Typography>
            <Typography>(5) You may withdraw your consent at any time without affecting the lawfulness of processing based on consent before its withdrawal. The easiest way to withdraw your consent is via our Consent Manager or via the following functions:</Typography>
            <List sx={{ listStyle: "lower-alpha", pl: 4 }}>
              <ListItemText sx={{ display: "list-item" }}>by setting your browser software accordingly; in particular, the suppression of third-party cookies means that you will not receive any adverts from third-party providers;</ListItemText>
              <ListItemText sx={{ display: "list-item" }}>by setting your browser to block cookies from the domain <Link target="_blank" rel="norefferrer" href="www.googleadservices.com">www.googleadservices.com</Link>, <Link target="_blank" rel="norefferrer" href="www.google.de/settings/ads">www.google.de/settings/ads</Link>, whereby this setting is deleted when you delete your cookies;</ListItemText>
              <ListItemText sx={{ display: "list-item" }}>by deactivating the interest-based ads of the providers that are part of the "About Ads" self-regulation campaign via the link <Link target="_blank" rel="norefferrer" href="www.aboutads.info/choices">www.aboutads.info/choices</Link>, whereby this setting is deleted when you delete your cookies;</ListItemText>
              <ListItemText sx={{ display: "list-item" }}>by permanently deactivating it in your Firefox, Internet Explorer or Google Chrome browsers under the link <Link target="_blank" rel="norefferrer" href="www.google.com/settings/ads/plugin">www.google.com/settings/ads/plugin</Link>. We would like to point out that in this case you may not be able to use all the functions of this website to their full extent.</ListItemText>
            </List>
            <Typography>(6) Further information on data protection at Google Ireland Limited, Gordon House, Barrow Street Dublin 4, Ireland, can be found here: <Link target="_blank" rel="norefferrer" href="https://policies.google.com/privacy?hl=en">https://policies.google.com/privacy?hl=en</Link> and <Link target="_blank" rel="norefferrer" href="https://services.google.com/sitestats/en.html">https://services.google.com/sitestats/en.html</Link>.</Typography>
          </Stack>

          <Stack spacing={1}>
            <Typography variant="h5">§ 13 Data security</Typography>
            <Typography>All information you submit to us is stored on servers within the European Union. Unfortunately, the transmission of information via the Internet is not completely secure, which is why we cannot guarantee the security of data transmitted to our website via the Internet. However, we take technical and organisational measures to protect our website and other systems against loss, destruction, access, modification or dissemination of your data by unauthorised persons. In particular, your personal data is transmitted to us in encrypted form. We use the SSL (Secure Socket Layer) or TLS (Transport Layer Security) coding system to prevent access by unauthorised third parties. You can recognise this in your browser by the lock symbol and the additional "s" at https, i.e. when the Internet address begins with "https".</Typography>
          </Stack>

          <Stack spacing={1}>
            <Typography variant="h5">§ 14	Involvement of service providers, disclosure of personal data to third parties and recipients</Typography>
            <Typography>In order to provide this website and for the aforementioned purposes, your data may be passed on to technical service providers who support us (namely DigitalOcean LLC for website hosting and support and the mailing service of Sendinblue GmbH for the sending of e-mail newsletters), which we have carefully selected and commissioned as part of order processing in accordance with Art. 28 GDPR. These service providers are bound by our instructions and are regularly monitored by us.</Typography>
            <Typography>We do not disclose your personal data to third parties beyond this unless you have consented to the disclosure of data or we are authorised or obliged to disclose data on the basis of statutory provisions and/or official or court orders. In particular, this may involve the provision of information for the purposes of criminal prosecution, defence against danger or the enforcement of intellectual property rights.</Typography>
            <Typography>Internally, only our marketing and IT departments have access to the data.</Typography>
          </Stack>

          <Stack spacing={1}>
            <Typography variant="h5">§ 15	Data protection and third-party websites</Typography>
            <Typography>The website may contain hyperlinks to and from third-party websites. If you follow a hyperlink to one of these websites, please note that we cannot accept any responsibility or guarantee for third-party content or data protection conditions. Please check the applicable data protection conditions before you transmit personal data to these websites.</Typography>
          </Stack>

          <Stack spacing={1}>
            <Typography variant="h5">§ 16	Changes to 	these data protection provisions</Typography>
            <Typography>We reserve the right to amend this privacy policy at any time with effect for the future. A current version is always available on the website. Please visit the website regularly and inform yourself about the applicable data protection provisions.</Typography>
          </Stack>

          <Typography variant="h5" fontWeight="bold">May 2024</Typography>
        </Stack>
      </BasePage>
    </CookieIgnoreHandler>
  );
}

export default PrivacyPage;