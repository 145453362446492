import { Drawer, useMediaQuery, useTheme } from "@mui/material";
import { useMemo } from "react";
import DrawerContent from "./DrawerContent";
import DrawerHeader from "./DrawerHeader";

export const drawerWidth = 300;

interface CustomDrawerProps{
    open: boolean;
    closeDrawer: () => void
}

const CustomDrawer = (props: CustomDrawerProps) => {
    const theme = useTheme();
    const useTemporary = useMediaQuery(theme.breakpoints.down('sm'));
    const drawerContent = useMemo(() => <DrawerContent />, []);
    const drawerHeader = <DrawerHeader open={props.open} closeDrawer={props.closeDrawer}/>;

    return (
        
            <Drawer
                variant={useTemporary ? "temporary" : "persistent"}
                onClose={useTemporary ? () => {props.closeDrawer()} : undefined}
                open={props.open}
                ModalProps={{ keepMounted: true }}
                sx={{

                    display: { lg: 'block' },
                    width: drawerWidth,
                    '& .MuiDrawer-paper': {
                        boxSizing: 'border-box',
                        width: drawerWidth,
                        borderRight: '1px solid',
                        borderRightColor: 'divider',
                        backgroundImage: 'none',
                        boxShadow: 'inherit'
                    },
                }}
            >
                {drawerHeader}
                {drawerContent}
            </Drawer>
        
    );
}

export default CustomDrawer;