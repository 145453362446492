import { Box, Toolbar, Typography, useMediaQuery, useTheme } from "@mui/material"
import StyledAppBar from "../../components/StyledAppBar";
import { PropsWithChildren } from "react";
import HomeButton from "../../components/HomeButton";

interface HeaderProps{
    title: string
    useLinks?: boolean
}
const Header = (props: PropsWithChildren<HeaderProps>) => {
    const theme = useTheme();
    const showTitle = useMediaQuery(theme.breakpoints.up('sm'))
    
    return(<StyledAppBar
        position="fixed"
        color="inherit"
        elevation={0}
        sx={{
            
            borderBottom: `1px solid ${theme.palette.divider}`
        }}
    >
        <Toolbar>
            <Box display="flex" flex="1" flexDirection="row" justifyContent="space-between" alignItems="center">
            <HomeButton useLinks={props.useLinks}/>
            {(showTitle || !props.children) && <Typography variant="h4">{props.title}</Typography>}
            {props.children}
            </Box>
        
        </Toolbar>
            
    </StyledAppBar>)
}

export default Header;