import { OidcClient, OidcConfiguration, OidcProvider } from "@axa-fr/react-oidc";
import { CircularProgress, Typography } from "@mui/material";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import VerticalLayoutPage from "./VerticalLayoutPage";

const oidcConfig: OidcConfiguration = {
    authority: process.env.REACT_APP_OIDC_AUTHORITY!,
    client_id: process.env.REACT_APP_OIDC_CLIENT_ID!,
    redirect_uri: window.location.origin + "/authentication/callback",
    silent_redirect_uri: window.location.origin + "/authentication/silent-callback",
    scope: "openid xid profile email offline_access",
    service_worker_only: false,
    preload_user_info: true,
};

const Loading = () => <VerticalLayoutPage appBarTitle="Loading" title="Loading..."><CircularProgress /></VerticalLayoutPage>
const AuthenticatingError = () => <VerticalLayoutPage appBarTitle="Error" title="Oops!"><Typography variant="h5" textAlign="center">Sorry, an unexpected error has occured.</Typography></VerticalLayoutPage>;
const Authenticating = () => <VerticalLayoutPage appBarTitle="Loading" title="Authenticating..."><CircularProgress /></VerticalLayoutPage>;
const SessionLost = () => <VerticalLayoutPage appBarTitle="Session Lost" title="Timeout"><Typography variant="h5" textAlign="center">Your session expired, please login again!</Typography></VerticalLayoutPage>;
const CallbackSuccess = () => <VerticalLayoutPage appBarTitle="Authentication" title="Success!"><Typography variant="h5" textAlign="center">You have successfully logged in. Redirecting...</Typography></VerticalLayoutPage>;


const GlobalAuthProvider = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const onEvent = (configName: string,eventName: string, data: any) => {
        if(location.pathname.startsWith('/authentication/callback')){
            if(configName === 'default'){
                if(eventName === 'loginCallbackAsync_error'){
                    const error = data as Error;
                    if(error.message.startsWith("State not valid (expected: undefined, received:")){
                        navigate('/email_confirmed',{
                            replace: true
                        });
                        window.location.reload();
                    }
                }
                
            }
        }
    }
    return (
        
        <OidcProvider
            configurationName="default"
            configuration={oidcConfig}
            loadingComponent={Loading}
            authenticatingErrorComponent={AuthenticatingError}
            authenticatingComponent={Authenticating}
            sessionLostComponent={SessionLost}
            callbackSuccessComponent={CallbackSuccess}
            onEvent={onEvent}
        ><Outlet />
        </OidcProvider>);
}


export default GlobalAuthProvider;