
export interface Cookie {
    name: string;
    description: string;
    duration: string;
}

export interface CookieGroup {
    name: string;
    description: string;
    vendor: string;
    host: string;
    privacyUrl: string;
    cookies: Cookie[];
    autocleanCookies: boolean;
    onConsentGiven: () => void;
    onConsentRemoved: () => void;
}

export interface CookieCategory {
    id: string;
    name: string;
    description: string;
    disabled: boolean;
    default: boolean;
    groups: CookieGroup[];
}

const consentCookieGroup: CookieGroup = {
    name: "Cookie Consent",
    description: "Used for storage of Cookie consent.",
    vendor: "Piton GmbH",
    host: "freelancer-toolbox.com",
    privacyUrl: "https://freelancer-toolbox.com/privacy",
    autocleanCookies: true,
    cookies: [
        {
            name: "cookie_consent",
            duration: "1 year",
            description: "We set this cookie to remember the consent choice",
        }
    ],
    onConsentGiven: () => { },
    onConsentRemoved: () => { },

}

const keycloakCookieGroup: CookieGroup = {
    name: "Keycloak",
    description: "Used for authentication.",
    vendor: "Piton GmbH",
    host: "*.freelancer-toolbox.com",
    privacyUrl: "https://freelancer-toolbox.com/privacy",
    autocleanCookies: true,
    cookies: [
        {
            name: "KEYCLOAK_SEESION",
            duration: "1 hour",
            description: "Session cookie.",
        },
        {
            name: "KEYCLOAK_SEESION_LEGACY",
            duration: "1 hour",
            description: "Legacy session cookie.",
        },
        {
            name: "KEYCLOAK_IDENTITY",
            duration: "session",
            description: "Used for identification.",
        },
        {
            name: "KEYCLOAK_IDENTITY_LEGACY",
            duration: "session",
            description: "Used for legacy identification.",
        },
        {
            name: "AUTH_SESSION_ID",
            duration: "session",
            description: "Used for authentication.",
        },
        {
            name: "AUTH_SESSION_ID_LEGACY",
            duration: "session",
            description: "Used for legacy authentication.",
        },

    ],
    onConsentGiven: () => { },
    onConsentRemoved: () => { },
}
export const cookieCategories: CookieCategory[] = [
    {
        id: "necessary",
        name: "Necessary",
        description: "Necessary Cookies help us to make this website usable. They will be used for functions such as site navigation. This website will not function properly without these Cookies.",
        disabled: true,
        default: true,
        groups: [consentCookieGroup, keycloakCookieGroup]
    },
    /*{
        id: "marketing",
        name: "Marketing",
        description: "Marketing",
        disabled: false,
        default: false,
        groups:[]
    }*/
]

export function shouldShowDialog(consentCookie: any): boolean {
    if (consentCookie !== undefined) {
        if (typeof (consentCookie) === 'object') {
            for (const category of cookieCategories) {
                if (consentCookie.hasOwnProperty(category.id)) {
                    if (typeof (consentCookie[category.id]) !== 'boolean') {
                        return true;
                    }
                } else {
                    return true;
                }
            }
            return false;
        }
    }
    return true;
}

export function readConsentValues(consentCookie: any): any{
    var values:any = {};
    for(const category of cookieCategories){
        if(consentCookie !== undefined && typeof(consentCookie) === 'object'){
            if(consentCookie.hasOwnProperty(category.id) && typeof (consentCookie[category.id]) === 'boolean'){
                values[category.id] = consentCookie[category.id];
                continue;
            }
        }
        values[category.id] = null;
    }
    return values;
}

export function checkedConsentValues(consent: any) : any{
    var checkedValues:any={};
    for(const category of cookieCategories){
        checkedValues[category.id] = category.disabled ? category.default : (consent[category.id] === null ? category.default : consent[category.id]);
    }
    return checkedValues;
}

export function giveInitialConsent(consent: any){
    for(var category of cookieCategories){
        if(consent[category.id]){
            giveConsentForCategory(category);
        }
    }   
}
export function replaceConsent(newConsent: any, oldConsent: any, removeCookie: (name: string) => void){
    for(var category of cookieCategories){
        if(oldConsent[category.id] !== newConsent[category.id]){
            if(newConsent[category.id]){
                giveConsentForCategory(category);
            }else {
                removeConsentForCategory(category, removeCookie);
            }
        }
    }
}

function giveConsentForCategory(category: CookieCategory){
    for(var group of category.groups){
        group.onConsentGiven();
    }
}

function removeConsentForCategory(category: CookieCategory, removeCookie: (name:string) => void){
    for(var group of category.groups){
        group.onConsentRemoved();
        if(group.autocleanCookies){
            for(var c of group.cookies){
                removeCookie(c.name);
            }
        }
    }
}

