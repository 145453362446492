import { Stack, Typography } from "@mui/material";
import { isRouteErrorResponse, useRouteError } from "react-router-dom";
import VerticalLayoutPage from "../components/VerticalLayoutPage";


const ErrorPage = () => {
  const error = useRouteError();
  let errorMessage: string;
  if (isRouteErrorResponse(error)) {
    errorMessage = error.status + " " + error.statusText;
  } else if (error instanceof Error) {
    errorMessage = error.message;
  } else if (typeof error === 'string') {
    errorMessage = error;
  } else {
    console.error(error);
    errorMessage = 'Unknown error';
  }
  return (
    <VerticalLayoutPage appBarTitle="Error" title="Oops!">
      <Stack>
          <Typography variant="h5" textAlign="center">Sorry, an unexpected error has occured.</Typography>
          <Typography variant="h6" textAlign="center">{errorMessage}</Typography>
        </Stack>
    </VerticalLayoutPage>); 
}

export default ErrorPage;