import { OidcUserStatus, useOidc, useOidcUser } from "@axa-fr/react-oidc";
import { Avatar, Box, Button, CircularProgress, Grid, Stack, Typography } from "@mui/material";
import PortalContent from "../layout/portal/PortalContent";
import PortalCard from "../components/PortalCard";
import { useState } from "react";
import FileUploadDialog from "../components/FileUploadDialog";
import { useCertification } from "../utils/hooks";

const ProfileCard = () => {
    const {login,logout } = useOidc('default');
    const { oidcUser, oidcUserLoadingState } = useOidcUser('default');
    var child: JSX.Element;
    if (oidcUserLoadingState === OidcUserStatus.Loaded) {
        child = <Stack spacing={2}>
            <Box>
            <Typography>Name: {oidcUser.name}</Typography>
            <Typography>Email: {oidcUser.email}</Typography>
            </Box>
            
            <Button sx={{ mt: 3 }} variant="contained" onClick={() => {
                logout("/");
            }}>Logout</Button>
            <Button variant="contained" onClick={() => {
                login(undefined,{kc_action: 'UPDATE_PASSWORD'});
            }}>Change Password</Button>
        </Stack>
    } else {
        child = <CircularProgress />
    }
    return (<PortalCard title="Profile">{child}</PortalCard>);
}


const CertificationCard = () => {
    const {label:certified} = useCertification();
    const [dialogOpen, openDialog] = useState<boolean>(false);
    var label: string;
    var color: string;
    var showFileUpload = false;
    if (certified === 'certified') {
        label = 'Certified';
        color = 'green';
    } else if (certified === 'pending') {
        label = 'Pending';
        color = 'orange';
    } else if (certified === 'uncertified') {
        label = 'Uncertified';
        color = 'yellow';
        showFileUpload = true;
    } else if (certified === 'rejected') {
        label = 'Rejected';
        color = 'red';
        showFileUpload = true;
    } else {
        label = 'Unknown';
        color = 'grey';
    }

    return (<PortalCard title="Certification">
        <Stack spacing={3}>
            <Stack direction="row" spacing={2}>
                <Typography>Status:</Typography>
                <Avatar sx={{
                    width: 20,
                    height: 20,
                    bgcolor: color
                }}>{" "}
                </Avatar>
                <Typography>{label}</Typography>
            </Stack>
            <Stack direction="row" spacing={2}>
                <Button disabled={!showFileUpload} variant="contained" onClick={() => {openDialog(true)}}>Upload File</Button>
                <FileUploadDialog open={dialogOpen} onClose={() => {openDialog(false)}}></FileUploadDialog>
            </Stack>
        </Stack>
    </PortalCard>);
}

const AccountPage = () => {    
    return (
        <PortalContent title="Account" breadcrumbs={[]}>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                    <ProfileCard/>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <CertificationCard/>
                </Grid>
            </Grid>

        </PortalContent>
    );
}

export default AccountPage;